import CallApi from '../../../services/api.service';

const getListMerchantOb = async ({ commit }, params) => {
    const request = await CallApi.getListMerchantOb(params);
    if (request) {
        commit('FETCH_MERCHANT_OB_LIST', request);
        return request;
    }
}
const createSubMerchantOnboard = async (_, params) => {
    const request = await CallApi.createSubMerchantOnboard(params);
    return request;
}

const getInfoSubMerchantOnboard = async ({ commit }, params) => {
    const request = await CallApi.getSubMerchantOnboardDetail(params);
    if (request) {
        commit('FETCH_MERCHANT_OB_DETAIL', request);
        return request;
    }
}

const updateSubMerchantOnboard = async (_, {params, id}) => {
    console.log([...params.entries()]);
    console.log(id)
    const request = await CallApi.updateSubMerchantOnboard(params, id);
    return request;
}

export default {
    getListMerchantOb,
    createSubMerchantOnboard,
    getInfoSubMerchantOnboard,
    updateSubMerchantOnboard
}