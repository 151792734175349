const DEPOSIT_AMOUNT = 1;
const DECREMENT_DEPOSIT_AMOUNT = 2;
const HOLD_MONEY_AMOUNT = 3;
const DECREMENT_HOLD_MONEY_AMOUNT = 4;

const DEPOSIT_INSTANT = 1; //Ký quỹ tức thì
const DEPOSIT_DEDUCT = 2; //Ký quỹ khấu trừ
const DEPOSIT_RETURN = 3; //Ký quỹ hoàn trả
const DEPOSIT_DECREMENT_FREEZE = 4; //Mở băng ký quỹ
const DEPOSIT_INSTANT_HOLD = 5; //Giam tiền tức thì
const DEPOSIT_DEDUCT_HOLD = 6; //Giam tiền khấu trừ
const DEPOSIT_RETURN_HOLD = 7; //Giam tiền hoàn trả
const DEPOSIT_DROP_MONEY = 8; //Bỏ giam tiền

const TYPE_DISPLAY_FREEZE_TYPE = {
    [DEPOSIT_AMOUNT]: 'Constant.MerchantFreezeBalance.DepositAmount',
    [DECREMENT_DEPOSIT_AMOUNT]: 'Constant.MerchantFreezeBalance.DecrementDepositAmount',
    [HOLD_MONEY_AMOUNT]: 'Constant.MerchantFreezeBalance.HoldMoneyAmount',
    [DECREMENT_HOLD_MONEY_AMOUNT]: 'Constant.MerchantFreezeBalance.DecrementHoldMoneyAmount',
};

const INCREMENT_FREEZE = 1; //đóng băng
const DECREMENT_FREEZE = 2; //mở băng

export default {
    DEPOSIT_AMOUNT,
    DECREMENT_DEPOSIT_AMOUNT,
    HOLD_MONEY_AMOUNT,
    DECREMENT_HOLD_MONEY_AMOUNT,
    TYPE_DISPLAY_FREEZE_TYPE,
    DEPOSIT_INSTANT,
    DEPOSIT_DEDUCT,
    DEPOSIT_RETURN,
    DEPOSIT_DECREMENT_FREEZE,
    DEPOSIT_INSTANT_HOLD,
    DEPOSIT_DEDUCT_HOLD,
    DEPOSIT_RETURN_HOLD,
    DEPOSIT_DROP_MONEY,
    INCREMENT_FREEZE,
    DECREMENT_FREEZE
}