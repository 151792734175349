import CallApi from '../../../services/api.service';

const index = async ({ commit }, params ) => {
    const request  = await CallApi.getMerchantFreezeBalanceLog(params);
    if (request.data) {
        commit('GET_MERCHANT_FREEZE_BALANCE_SUCCESS', request.data);
    }
    return request;
}

export default {
    index,
}
