import helper from '../../../until/helper';

const GET_MERCHANT_FREEZE_BALANCE_SUCCESS = (state, data) => {
    if (helper.isset(data.paginate)) {
        state.merchantFreezeBalanceList = data.paginate.data;
        state.currentPage = data.paginate.current_page;
        state.total       = data.paginate.total;
    }

    if (helper.isset(data.total)) {
        state.totalFreezeBalance.totalFreezeBalance = data.total.freeze_amount;
        state.totalFreezeBalance.totalDecrementFreezeBalance = data.total.decrement_freeze_amount;
    }
};

export default {
    GET_MERCHANT_FREEZE_BALANCE_SUCCESS,
};
