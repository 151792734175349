const FETCH_MERCHANT_OB_LIST = (state, response) => {
    state.merchantList = response.data.data;
    state.currentPage = response.data.current_page;
    state.total       = response.data.total;
}

const FETCH_MERCHANT_OB_DETAIL = (state, response) => {
    state.merchantDetail = response.data.onboard_info;
}

export default {
    FETCH_MERCHANT_OB_LIST,
    FETCH_MERCHANT_OB_DETAIL
}