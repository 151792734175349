const getMerchantFreezeBalanceList = state => state.merchantFreezeBalanceList;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;
const getTotalFreezeBalance = state => state.totalFreezeBalance;

export default {
    getMerchantFreezeBalanceList,
    getCurrentPage,
    getTotalRow,
    getPerPage,
    getTotalFreezeBalance
};
