import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    merchantFreezeBalanceList: null,
    currentPage: 1,
    total: 0,
    perPage: 20,
    totalFreezeBalance:{
        totalFreezeBalance: 0,
        totalDecrementFreezeBalance: 0
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
