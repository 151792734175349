const ATM_CARD = 'ATM_CARD';
const CREDIT_CARD = 'CREDIT_CARD';
const WALLET = 'WALLET';
const COLLECTION = 'COLLECTION';
const BUY_NOW_PAY_LATER = 'BUY_NOW_PAY_LATER';
const QR_PAY = 'QR_PAY';
const VN_PAY = 'VNPAY_PORTONE';
const ZALO_PAY = 'ZALOPAY_WALLET';
const APPLE_PAY = 'APPLE_PAY';
const GOOGLE_PAY = 'GOOGLE_PAY';
const INSTALLMENT = 'INSTALLMENT';

const TRANSACTION_METHOD = {
  [ATM_CARD]: 'Constant.TransactionMethod.AtmCard',
  [CREDIT_CARD]: 'Constant.TransactionMethod.CreditCard',
  [WALLET]: 'Constant.TransactionMethod.Wallet',
  [COLLECTION]: 'Constant.TransactionMethod.Collection',
  [BUY_NOW_PAY_LATER]: 'Constant.TransactionMethod.BuyNowPayLater',
  [QR_PAY]: 'Constant.TransactionMethod.QRPay',
  [APPLE_PAY]: 'Constant.TransactionMethod.ApplePAY',
  [GOOGLE_PAY]: 'Constant.TransactionMethod.GooglePAY',
};

export default {
  ATM_CARD,
  CREDIT_CARD,
  WALLET,
  COLLECTION,
  BUY_NOW_PAY_LATER,
  QR_PAY,
  VN_PAY,
  ZALO_PAY,
  TRANSACTION_METHOD,
  APPLE_PAY,
  INSTALLMENT,
  GOOGLE_PAY
}
