<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs">
      <a-button
          class="float-end btn-create mt-3"
          type="primary"
          icon="plus"
          @click="createMerchantOnboard"
      >
        {{ $t('Button.Add') }}
      </a-button>
    </PageTitle>
    <a-card class="mb-30 shadow">
      <a-row class="pb-2">
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-input
              ref="orderCode"
              v-model="searchInput.merchant_name"
              :placeholder="$t('MerchantOnboard.placeHolderMerchantName')"
          >
            <a-icon slot="suffix" type="search" />
          </a-input>
        </a-col>
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2">
          <a-select
              ref="type"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              v-model="searchInput.sub_merchant_status"
              default-value=""
              style="width: 100%"
          >
            <a-icon slot="suffixIcon" type="menu" />
            <a-select-option value="">{{ $t('MerchantOnboard.placeHolderStatus') }}</a-select-option>
            <a-select-option
                v-for="status in filterStatus"
                :key="status.id"
                :value="status.id"
            >
              {{ status.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :xs="{ span: 24 }" :xl="{ span: 6 }" class="pr-10 pl-10 mb-3 my-xl-2 d-flex d-xl-block justify-content-center">
          <div>
            <a-button type="primary" class="me-2" @click="search">{{ $t('Button.Search') }}</a-button>
            <a-button type="secondary" class="me-2" @click="clear">{{ $t('Button.Cancel') }}</a-button>
          </div>
        </a-col>
      </a-row>
    </a-card>
    <a-spin :spinning="this.loading" size="large">
      <a-card class="mb-30 shadow">
          <a-table
              :data-source="merchantList"
              :columns="columns"
              :row-key="record => record.id"
              :pagination="false"
              class="mb-30 nowrap"
              :scroll="{x: 'auto'}"
          >
            <template #customTitleNo>
              <div class="no">
                <p align="center" class="mb-0">{{ $t('MerchantOnboard.no') }}</p>
              </div>
            </template>
            <template slot="no" slot-scope="text, record, index">
              <p style="font-weight: bold" class="mb-0">{{ (searchInput.page - 1) * perPage + index + 1 }}</p>
            </template>

            <template #customTitleCreatedAt>
              <div class="created_at">
                <p align="center" class="mb-0">{{ $t('MerchantOnboard.createdAt') }}</p>
              </div>
            </template>
            <template slot="created_at" slot-scope="text, record">
              <p align="center" class="mb-0">{{ showFormatDate(record.created_at, "HH:mm:ss DD/MM/YYYY") }}</p>
            </template>

            <template #customTitleMerchantName>
              <div class="merchant_name">
                <p align="left" class="mb-0">{{ $t('MerchantOnboard.merchantName') }}</p>
              </div>
            </template>
            <template slot="merchant_name" slot-scope="text, record">
              <p align="left" class="mb-0">{{ record.merchant_name }}</p>
            </template>

            <template #customTitleShortName>
              <div class="short_name">
                <p align="left" class="mb-0">{{ $t('MerchantOnboard.merchantShortName') }}</p>
              </div>
            </template>
            <template slot="short_name" slot-scope="text, record">
              <p align="left" class="mb-0">{{ record.short_name }}</p>
            </template>

            <template #customTitleStatus>
              <div class="status">
                <p align="center" class="mb-0">{{ $t('MerchantOnboard.status') }}</p>
              </div>
            </template>
            <template slot="status" slot-scope="text, record">
              <p align="center" class="mb-0" v-html="showAccountStatusListHTML(record.risk_status, record.legal_status)"></p>
            </template>

            <template #customTitleAction >
              <p align="center" class="mb-0">{{ $t('MerchantOnboard.action') }}</p>
            </template>
            <template slot="action" slot-scope="text, record" >
              <div align="center">
                <router-link
                    :to="{name: 'UpdateSubMerchantOnboard', params: { id: record.id }}"
                    class="me-2"
                >
                  <a-tooltip>
                    <template slot="title">
                      <span> {{ $t('MerchantOnboard.edit') }}</span>
                    </template>
                    <a><a-icon slot="prefix" type="form"/></a>
                  </a-tooltip>
                </router-link>
                <router-link
                    :to="{name: 'SubMerchantOnboardDetail', params: { id: record.id }}"
                    class="me-2"
                >
                  <a-tooltip>
                    <template slot="title">
                      <span> {{ $t('MerchantOnboard.detail') }}</span>
                    </template>
                    <a><a-icon slot="prefix" type="eye"/></a>
                  </a-tooltip>
                </router-link>
              </div>
            </template>
          </a-table>
      </a-card>
    </a-spin>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from "@/layout/AppMaster.vue";
import PageTitle from "@/components/PageTitle";
import {mapGetters} from "vuex";
import helper from "@/until/helper";
import moment from "moment";
import MerchantOnboard from "@/constants/MerchantOnboard";

export default {
  name: "listSubMerchantOnboard",
  components: {
    AppMasterVue,
    PageTitle,
  },
  data(){
    return {
      loading: false,
      title: 'Title.SubMerchantOnboardList',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.SubMerchantOnboardList',
          routeName: 'ListSubMerchantOnboard',
          active: true,
        }
      ],
      searchInput: {
        type: 3,
        page: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
        merchant_name: this.isset(this.$route.query.merchant_name) ? this.$route.query.merchant_name : "",
        sub_merchant_status: this.isset(this.$route.query.sub_merchant_status) && this.$route.query.sub_merchant_status != '' ? parseInt(this.$route.query.sub_merchant_status) : '',
      },
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          align: "center",
          display: true,
          class: ''
        },
        {
          key: "created_at",
          slots: { title: "customTitleCreatedAt" },
          scopedSlots: { customRender: "created_at" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "merchant_name",
          slots: { title: "customTitleMerchantName" },
          scopedSlots: { customRender: "merchant_name" },
          display: true,
          align: "left",
          class: ''
        },
        {
          key: "short_name",
          slots: { title: "customTitleShortName" },
          scopedSlots: { customRender: "short_name" },
          display: true,
          align: "left",
          class: ''
        },
        {
          key: "status",
          slots: { title: "customTitleStatus" },
          scopedSlots: { customRender: "status" },
          display: true,
          align: "center",
          class: ''
        },
        {
          key: "action",
          slots: { title: "customTitleAction" },
          scopedSlots: { customRender: "action" },
        },
      ],
      filterStatus: [],
    }
  },
  computed:{
    ...mapGetters({
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
      lang: 'authentications/getLang',
      profileMerchant: "authentications/getProfileMerchant",
      merchantList: "merchantOnboard/merchantList",
      totalRow: "merchantOnboard/getTotalRow",
      perPage: "merchantOnboard/getPerPage",
    }),
  },
  mounted(){
    this.searchInput.merchant_partner = String(this.profileMerchant.id ?? '')
    this.filterStatus = [
      { id: MerchantOnboard.NOT_EVALUATED, name: this.$t('MerchantOnboard.notEvaluated') },
      { id: MerchantOnboard.EVALUATING, name: this.$t('MerchantOnboard.evaluating') },
      { id: MerchantOnboard.APPROVED_CONNECTION, name: this.$t('MerchantOnboard.approvedConnection') },
      { id: MerchantOnboard.CONNECTION_REFUSED, name: this.$t('MerchantOnboard.connectionRefused') },
      { id: MerchantOnboard.ADDITIONAL_REQUIREMENTS, name: this.$t('MerchantOnboard.additionalRequirements') }
    ];
    this.callApiMerchantList();
  },
  methods:{
    isset: helper.isset,
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    search(){
      this.callApiMerchantList();
    },
    clear(){
      this.searchInput.page = 1;
      this.searchInput.merchant_name = '';
      this.searchInput.sub_merchant_status = '';
    },
    setRouter() {
      this.$router.replace({
        name: "ListMerchantOnboard",
        query: this.searchInput,
      }).catch(() => {});
    },
    async callApiMerchantList() {
      this.loading = true;
      await this.$store.dispatch("merchantOnboard/getListMerchantOb", this.searchInput);
      this.loading = false;
    },
    showFormatDate(data = null, format = "DD/MM/YYYY HH:mm:ss") {
      if (moment(data).isValid) {
        return moment(data).format(format);
      }
      return moment().format(format);
    },
    showAccountStatusListHTML(riskStatus, legalStatus) {
      if (riskStatus === MerchantOnboard.RISK_NOT_EVALUATED && legalStatus === MerchantOnboard.LEGAL_NOT_EVALUATED){
        return '<p class="fw-bold text-dark text-center mb-0 p-1">'
            + this.$t(MerchantOnboard.OB_STATUS[MerchantOnboard.NOT_EVALUATED])
            + '</p>';
      }

      if (riskStatus === MerchantOnboard.RISK_EVALUATING || riskStatus === MerchantOnboard.RISK_PENDING_APPROVAL || legalStatus === MerchantOnboard.LEGAL_EVALUATING){
        return '<p class="fw-bold text-warning text-center mb-0 p-1">'
            + this.$t(MerchantOnboard.OB_STATUS[MerchantOnboard.EVALUATING])
            + '</p>';
      }

      if (riskStatus === MerchantOnboard.RISK_NOT_EVALUATED && legalStatus === MerchantOnboard.LEGAL_APPROVED_CONNECTION){
        return '<p class="fw-bold text-warning text-center mb-0 p-1">'
            + this.$t(MerchantOnboard.OB_STATUS[MerchantOnboard.EVALUATING])
            + '</p>';
      }

      if (riskStatus === MerchantOnboard.RISK_APPROVED_CONNECTION && legalStatus === MerchantOnboard.LEGAL_NOT_EVALUATED){
        return '<p class="fw-bold text-warning text-center mb-0 p-1">'
            + this.$t(MerchantOnboard.OB_STATUS[MerchantOnboard.EVALUATING])
            + '</p>';
      }


      if (riskStatus === MerchantOnboard.RISK_APPROVED_CONNECTION && legalStatus === MerchantOnboard.LEGAL_APPROVED_CONNECTION){
        return '<p class="fw-bold text-success text-center mb-0 p-1">'
            + this.$t(MerchantOnboard.OB_STATUS[MerchantOnboard.APPROVED_CONNECTION])
            + '</p>';
      }

      if (riskStatus === MerchantOnboard.RISK_CONNECTION_REFUSED || legalStatus === MerchantOnboard.LEGAL_CONNECTION_REFUSED){
        return '<p class="fw-bold text-danger text-center mb-0 p-1">'
            + this.$t(MerchantOnboard.OB_STATUS[MerchantOnboard.CONNECTION_REFUSED])
            + '</p>';
      }

      if (riskStatus === MerchantOnboard.RISK_ADDITIONAL_REQUIREMENTS || legalStatus === MerchantOnboard.LEGAL_ADDITIONAL_REQUIREMENTS){
        return '<p class="fw-bold text-primary text-center mb-0 p-1">'
            + this.$t(MerchantOnboard.OB_STATUS[MerchantOnboard.ADDITIONAL_REQUIREMENTS])
            + '</p>';
      }

      return '--';
    },
    createMerchantOnboard() {
      this.$router.push({name: 'CreateSubMerchantOnboard'});
    },
    showModalEdit(data){
      console.log(data)
    },
  },
  watch:{}
}
</script>