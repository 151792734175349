import client from './client';

class CallApi {
  // Core-1994 Transaction
  getTransactionDetail(data) {
    return client.get('transaction/view/' + data.orderCode, {amount: data.amount});
  }
  exportTransactions(params) {
    return client.get('transaction/export', params);
  }
  downloadTransactions(params) {
    return client.get('transaction/download', params);
  }

  fetchTotalInfoTransaction(params) {
    return client.post('transaction/get-total-transaction-by-filter', params);
  }

  // Core-1982
  getInvoices(params = null) {
    return client.get('invoice/index', params);
  }
  deleteInvoice(invoiceId, params) {
    return client.post('invoice/delete/' + invoiceId, params);
  }
  activeInvoice(invoiceId, params) {
    return client.post('invoice/active/' + invoiceId, params);
  }

  // Core-1987 - invoice
  createInvoice(params) {
    return client.post('invoice/create', params);
  }
  viewInvoice(invoiceId) {
    return client.get('invoice/view/' + invoiceId);
  }
  updateInvoice(params) {
    return client.post('invoice/update/' + params.id, params);
  }
  getInfo(params) {
    return client.get('invoice/getInfo', params);
  }
  getListMerchant() {
    return client.get('invoice/getListMerchant');
  }
  getListUserUpdate(params) {
    return client.post('user/get-list-user-update', params);
  }

  // Core-1997
  editSubAccount(params) {
    return client.post('profile/reset-password/' + params.id, params);
  }
  createSubAccount(params) {
    return client.post('profile/create-sub-account', params);
  }
  getListMember() {
    return client.get('profile/get-list-member');
  }

  // Core-1985
  fetchRefund(params = null) {
    return client.get('refund/index', params);
  }
  // Chi tiet hoan tien
  viewRefund(id) {
    return client.get('refund/' + id);
  }
  // Core-1986 Tao moi hoan tien
  createRefund(params) {
    return client.post('refund/create', params);
  }
  viewTransactionForRefund(data) {
    return client.get('refund/show-transaction/' + data.orderCode, {amount: data.amount});
  }
  cancelRefund(refundId) {
    return client.get('refund/cancel/' + refundId);
  }

  changeLanguage(lang) {
    return client.post('change-lang/' + lang);
  }

  // Core-1980 Dashboard
  getDataChart(params) {
    return client.post('dashboard/get-data-chart', params);
  }
  getBalanceMerchant(params) {
    return client.post('dashboard/get-balance-merchant', params);
  }
  getReportBalanceMerchant(params) {
    return client.post('dashboard/get-report-balance-merchant', params);
  }
  getExchangeRate() {
    return client.post('dashboard/get-exchange-rate');
  }
  getWalletBalance(params) {
    return client.post('dashboard/get-wallet-balance', params);
  }

  // Core-1984 Permission
  getAllPermission() {
    return client.post('role/get-all-permission');
  }
  getAllPermissionForUser(params) {
    return client.post('role/get-permission-for-user', params);
  }
  editPermissionSubMerchant(params) {
    return client.post('role/edit-permission-sub-merchant', params);
  }

  // Core-2087 Profile
  fetchProfileList(params = null) {
    return client.post('profile/index', params);
  }
  createProfile(params) {
    return client.post('profile/create', params, {"Content-Type": "multipart/form-data"});
  }
  editProfile(params) {
    return client.post('profile/edit/' + params.get('id'), params, {"Content-Type": "multipart/form-data"});
  }
  viewProfile(profileId) {
    return client.post('profile/view/' + profileId);
  }
  deleteProfile(profileId) {
    return client.post('profile/delete/' + profileId);
  }
  editStatusActive(id, params = null) {
    return client.post('profile/edit/status-active/'+ id, params);
  }
  getLinkInfo() {
    return client.get('link-info/view');
  }
  createLinkInfo(params) {
    return client.post('link-info/create', params);
  }

  getProfileInfo(params) {
    return client.get('profile/getProfileInfo', params);
  }

  // Core-2172 MerchantBalanceLog
  getMerchantBalanceLog(params) {
    return client.get('merchant-balance-log', params);
  }
  
  // Core-2914 MerchantDisbursementLog
  getMerchantDisbursementLog(params) {
    return client.get('merchant-disbursement-log', params);
  }
  getMerchantUsdLog(params) {
    return client.get('merchant-usd-log', params);
  }
  // Core-2428 SchoolPaymentPage
  viewOrderPayment(orderCode) {
    return client.post('order-payment-page/view/' + orderCode);
  }
  fetchOrderPayment(params) {
    return client.post('order-payment-page/index', params);
  }
  getPaymentPurposes(params) {
    return client.get('payment-school/index', params);
  }
  createPaymentPurpose(params) {
    return client.post('payment-school/create', params);
  }
  viewPaymentPurpose(id) {
    return client.get('payment-school/edit/' + id);
  }
  updatePaymentPurpose(params) {
    return client.post('payment-school/edit/' + params.id, params);
  }
  changeStatus(params) {
    return client.post('payment-school/changeStatus/' + params.id, params);
  }

  //Transaction disbursement
  getTransactionDisbursement(params) {
    return client.get('transaction/disbursement/index', params);
  }
  transactionDisbursementDetail(params) {
    return client.get('transaction/disbursement/detail/' + params.orderCode);
  }
  exportTransactionDisbursement(params) {
    return client.get('transaction/disbursement/export', params);
  }
  downloadFileDisbursement(params) {
    return client.get('transaction/disbursement/download-file', params);
  }

  downloadByBatch(params) {
    return client.get('transaction/disbursement/download-by-batch', params);
  }

  exportByBatch(params) {
    return client.post('transaction/disbursement/export-by-batch', params);
  }

  getListHistoryByBatch(params) {
    return client.get('transaction/disbursement/list-history', params);
  }

  getListAccountTransfer(params) {
    return client.post('transaction/disbursement/list-account-transfer/' + params.history_id, params);
  }

  //Wallet
  getBalanceLogWallet(params) {
    return client.post('wallet/get-balance-log-wallet', params);
  }
  downloadBalanceLogWallet(params) {
    return client.post('wallet/get-balance-log-wallet', params);
  }
  getOrdersHistoryWallet(params) {
    return client.post('wallet/get-order-history-of-user-wallet', params);
  }

  //CORE-3405
  getAccount(params){
    return client.post('account/listAccount', params);
  }

  //Request Dibursement
  getListBank() {
    return client.get('transaction/disbursement/get-list-bank');
  }
  checkAccount(params) {
    return client.get('transaction/disbursement/check-account', params);
  }
  createRequestDisbursement(params) {
    return client.post('transaction/disbursement/create-request-disbursement', params);
  }
  createRequestWithdraw(params) {
    return client.post('transaction/disbursement/create-request-withdraw', params)
  }
  getBalanceAvailable() {
    return client.post('transaction/disbursement/get-balance-available')
  }
  getDisbursementExtraData(merchantID){
    return client.get('transaction/disbursement/get-disbursement-extra-data/' + merchantID)
  }
  getAccountWithdraw() {
    return client.post('transaction/disbursement/get-account-withdraw')
  }
  createRequestByBatch(params) {
    return client.post('transaction/disbursement/request-by-batch', params)
  }
  getListByHistory(params) {
    return client.post('transaction/disbursement/get-list-by-history/' + params.history_id, params)
  }
  getActionLogs(params) {
    return client.get('transaction/disbursement/get-action-logs/' + params.historyId)
  }
  getSumDataByHistory(params) {
    return client.get('transaction/disbursement/get-sum-by-history/' + params.historyId)
  }
  checkAccountByBatch(params) {
    return client.post('transaction/disbursement/check-account-by-batch', params);
  }
  getFeeByBatch(params) {
    return client.post('transaction/disbursement/get-fee-by-batch', params);
  }
  confirmByBatch(params) {
    return client.post('transaction/disbursement/confirm-by-batch', params);
  }
  checkAccountValidData(params) {
    return client.post('transaction/disbursement/check-account-valid-data', params);
  }
  //paygate 2671 logic refund
  pushNotiCancelInstallment(params){
    return client.post('transaction/cancel_installment_conversion', params);
  }

  //refund installment request
  fetchRefundInstallmentReqList(params){
    return client.post('request-refund-installment/list', params);
  }

  fetchVirtualUserList(params = null) {
    return client.post('virtual-user/list', params);
  }
  fetchVirtualAccountListAll(params = null) {
    return client.post('virtual-user/get-all-accounts', params);
  }
  createVirtualUser(params = null) {
    return client.post('virtual-user/create', params);
  }
  updateVirtualUser(params = null) {
    return client.post('virtual-user/update', params);
  }
  deleteVirtualUser(params = null) {
    return client.post('virtual-user/delete', params);
  }
  fetchQRTransactionsList(params = null) {
    return client.post('virtual-user/transactions', params);
  }
  fetchTotalInfoTransactions(params){
    return client.post('virtual-user/transactions-total-info', params);
  }

  //virtual-account
  fetchVirtualAccountList(params){
    return client.post('virtual-account/list', params);
  }
  fetchBankNines(params){
    return client.post('virtual-account/get-bank-nines', params);
  }
  fetchVAListForManageModule(params){
    return client.post('virtual-account/index', params);
  }
  createVA(params){
    return client.post('virtual-account/create', params);
  }
  updateVA(params){
    return client.post('virtual-account/update', params);
  }
  getDataQRChart(params){
    return client.post('dash-board/get-data-store-qr', params);
  }

  getMenu = (merchantID) => {
    return client.get('get-menu/' + merchantID)
  }

  //security
  getGoogle2FAInfo() {
    return client.post('auth/google2fa/get-data');
  }
  enableGoogle2FA(params){
    return client.post('auth/google2fa/enable', params);
  }
  disableGoogle2FA(params){
    return client.post('auth/google2fa/disable', params);
  }
  createOTP(params){
    return client.post('auth/create-otp', params);
  }
  verifyOTP(params){
    return client.post('auth/verify-otp', params);
  }
  authLoginEnable(params){
    return client.post('auth/auth-login-enable', params);
  }
  //get otp

  getOpt(params){
    return client.post('auth/create-otp', params);
  }
  logOutDevice(params){
    return client.post('device/logout', params);
  }
  getListDevice = (params) => {
    return client.post('device', params);
  }

  //get list merchant freeze balance log
  getMerchantFreezeBalanceLog(params) {
    return client.get('merchant-freeze-balance-log', params);
  }

  //merchant onboard
  getListMerchantOb(params){
    return client.post('merchant-onboard/index', params);
  }
  createSubMerchantOnboard(params){
    return client.post('merchant-onboard/create', params, {"Content-Type": "multipart/form-data"})
  }
  getSubMerchantOnboardDetail(params) {
    return client.get('merchant-onboard/get-info/' + params.id);
  }
  updateSubMerchantOnboard(params, id) {
    console.log([...params.entries()]);
    return client.post('merchant-onboard/update/' + id, params,{"Content-Type": "multipart/form-data"});
  }
}

export default new CallApi;
