<template>
  <AppMasterVue >
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"> </PageTitle>

    <a-card class="ant-card merchant-onboard-card">
      <a-form-model
          layout="vertical"
          ref="dataCreateMerchantOBForm"
      >
        <a-row>
          <a-col :xs="{ span: 24 }" :xl="{ span: 12 }" class="pr-10 pl-10">
            <a-form-model-item
                ref="merchant_name"
                prop="merchant_name"
                :label-col="{ span: 12 }"
                :wrapper-col="{ span: 24 }"
            >
              <span slot="label"><b>{{ $t('MerchantOnboard.form.businessRegistrationName') }} (<span class="text-danger">*</span>)</b></span>
              <a-input
                  class="color-black"
                  v-model.trim="dataCreateMerchantOBForm.merchant_name"
              />
            </a-form-model-item>
            <a-form-model-item
                ref="short_name"
                prop="short_name"
                :label-col="{ span: 12 }"
                :wrapper-col="{ span: 24 }"
            >
              <span slot="label"><b>{{ $t('MerchantOnboard.form.merchantShortName') }} (<span class="text-danger">*</span>)</b></span>
              <a-input
                  class="color-black"
                  v-model.trim="dataCreateMerchantOBForm.short_name"
              />
            </a-form-model-item>
            <a-row>
              <a-col :span="14">
                <a-form-model-item
                    ref="business_registration_number"
                    prop="business_registration_number"
                    :label-col="{ span: 24 }"
                    :wrapper-col="{ span: 24 }"
                >
                  <span slot="label"><b>{{ $t('MerchantOnboard.form.businessRegistrationNumber') }} (<span class="text-danger">*</span>)</b></span>
                  <a-input
                      class="color-black"
                      v-model.trim="dataCreateMerchantOBForm.business_registration_number"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="2"></a-col>
              <a-col :span="8">
                <a-form-model-item
                    ref="business_registration_date"
                    prop="business_registration_date"
                    :label-col="{ span: 24 }"
                    :wrapper-col="{ span: 24 }"
                >
                  <span slot="label"><b>{{ $t('MerchantOnboard.form.issueDate') }} (<span class="text-danger">*</span>)</b></span>
                  <DatePicker
                      :date.sync="dataCreateMerchantOBForm.business_registration_date"
                      @onChangeDate="onChangeBusinessRegistrationDate"
                  ></DatePicker>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item
                ref="business_address"
                prop="business_address"
                :label-col="{ span: 12 }"
                :wrapper-col="{ span: 24 }"
            >
              <span slot="label"><b>{{ $t('MerchantOnboard.form.businessAddress') }} (<span class="text-danger">*</span>)</b></span>
              <a-input
                  class="color-black"
                  v-model.trim="dataCreateMerchantOBForm.business_address"
              />
            </a-form-model-item>
            <a-form-model-item
                ref="country"
                prop="country"
                :label-col="{ span: 12 }"
                :wrapper-col="{ span: 24 }"
            >
              <span slot="label"><b>{{ $t('MerchantOnboard.form.country') }} (<span class="text-danger">*</span>)</b></span>
              <a-input
                  class="color-black"
                  v-model.trim="dataCreateMerchantOBForm.country"
              />
            </a-form-model-item>
            <a-row>
              <a-col :span="14">
                <a-form-model-item
                    ref="business_representative"
                    prop="business_representative"
                    :label-col="{ span: 24 }"
                    :wrapper-col="{ span: 24 }"
                >
                  <span slot="label"><b>{{ $t('MerchantOnboard.form.businessRepresentative') }} (<span class="text-danger">*</span>)</b></span>
                  <a-input
                      class="color-black"
                      v-model.trim="dataCreateMerchantOBForm.business_representative"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="2"></a-col>
              <a-col :span="8">
                <a-form-model-item
                    ref="nationality"
                    prop="nationality"
                    :label-col="{ span: 24 }"
                    :wrapper-col="{ span: 24 }"
                >
                  <span slot="label"><b>{{ $t('MerchantOnboard.form.nationality') }} (<span class="text-danger">*</span>)</b></span>
                  <a-select
                      class="form-control nationality-code"
                      v-model="dataCreateMerchantOBForm.nationality"
                      dropdownClassName="nationality-code-dropdown"
                      showSearch
                      :filterOption="filterCountryCode"
                      option-label-prop="label"
                  >
                    <a-select-option
                        :value="item.name"
                        :label="item.name"
                        v-for="(item, index) in CountryCodeConstant.COUNTRY_CODE"
                        :key="item.name + '_' + index"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="14">
                <a-form-model-item
                    ref="representative_identification"
                    prop="representative_identification"
                    :label-col="{ span: 24 }"
                    :wrapper-col="{ span: 24 }"
                >
                  <span slot="label"><b>{{ $t('MerchantOnboard.form.passportOfRepresentative') }} (<span class="text-danger">*</span>)</b></span>
                  <a-input
                      class="color-black"
                      v-model.trim="dataCreateMerchantOBForm.representative_identification"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="2"></a-col>
              <a-col :span="8">
                <a-form-model-item
                    ref="representative_identification_date"
                    prop="representative_identification_date"
                    :label-col="{ span: 24 }"
                    :wrapper-col="{ span: 24 }"
                >
                  <span slot="label"><b>{{ $t('MerchantOnboard.form.issueDate') }} (<span class="text-danger">*</span>)</b></span>
                  <DatePicker
                      :date.sync="dataCreateMerchantOBForm.representative_identification_date"
                      @onChangeDate="onChangeShowSignerIdentificationDate"
                  ></DatePicker>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item
                ref="email"
                prop="email"
                :label-col="{ span: 12 }"
                :wrapper-col="{ span: 24 }"
            >
              <span slot="label"><b>{{ $t('MerchantOnboard.form.email') }} (<span class="text-danger">*</span>)</b></span>
              <a-input
                  class="color-black"
                  v-model.trim="dataCreateMerchantOBForm.email"
              />
            </a-form-model-item>
          </a-col>
          <a-col :xs="{ span: 24 }" :xl="{ span: 12 }" class="pr-10 pl-10">
            <a-form-model-item
                ref="careers"
                prop="careers"
                :label-col="{ span: 12 }"
                :wrapper-col="{ span: 24 }"
            >
              <span slot="label"><b>{{ $t('MerchantOnboard.form.careers') }} (<span class="text-danger">*</span>)</b></span>
              <a-input
                  class="color-black"
                  v-model.trim="dataCreateMerchantOBForm.careers"
              />
            </a-form-model-item>
            <a-form-model-item
                ref="register_method"
                prop="register_method"
                :label-col="{ span: 12 }"
                :wrapper-col="{ span: 24 }"
            >
              <span slot="label"><b>{{ $t('MerchantOnboard.form.register_method') }} (<span class="text-danger">*</span>)</b></span>
              <a-checkbox-group v-model="dataCreateMerchantOBForm.register_method" style="width:100%;">
                <a-row>
                  <a-col
                      v-for="(methodName, methodKey) in register_method"
                      :key="methodKey"
                      :span="12"
                      style="padding: 0 0 14px;">
                    <a-checkbox :value="methodKey">
                      {{ $t(methodName) }}
                    </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </a-form-model-item>
            <a-form-model-item
                ref="domain"
                prop="domain"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
            >
              <span slot="label"><b>{{ $t('MerchantOnboard.form.domain') }}</b></span>
              <table class="domain-table table table-bordered" style="width: 100%; border-collapse: collapse;">
                <thead>
                <tr>
                  <th style="width: 10%; text-align: center;">{{ $t('MerchantOnboard.no') }}</th>
                  <th style="width: 70%; text-align: center;">{{ $t('MerchantOnboard.form.domainName') }}</th>
                  <th style="width: 20%; text-align: center;">{{ $t('MerchantOnboard.form.action') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in dataCreateMerchantOBForm.domain" :key="index">
                  <td style="text-align: center;">{{ index + 1 }}</td>
                  <td>
                    <input
                        v-model="dataCreateMerchantOBForm.domain[index]"
                        placeholder="Nhập tên domain"
                        style="width: 100%; border: 1px solid #ddd; padding: 4px;"
                    />
                  </td>
                  <td style="text-align: center;">
                    <a-button type="danger" @click="removeRow(index)">
                      {{ $t('Xóa') }}
                    </a-button>
                  </td>
                </tr>
                </tbody>
              </table>
              <a-button class="w-100" type="primary" @click="addRow">{{ $t('MerchantOnboard.AddLine') }}</a-button>
            </a-form-model-item>
            <a-form-model-item
                ref="filenames"
                prop="filenames"
                :label-col="{ span: 12 }"
                :wrapper-col="{ span: 24 }"
            >
              <span slot="label"><b>{{ $t('MerchantOnboard.form.appraisalDocumentsAttached') }}</b></span>
              <a-form-model-item
                  :label-col="{ span: 24 }"
                  :wrapper-col="{ span: 24 }"
              >
                <a-upload
                    :multiple="false"
                    accept=".doc, .docx, .pdf, .txt, .rtf, .jpg, .jpeg, .png, .xls, .xlsx, .ppt, .pptx, .zip, .rar, .7z"
                    :customRequest="uploadfile"
                    :before-upload="beforeUploadFile"
                    list-type="text"
                    :fileList="fileList"
                    :remove="handleRemove"
                >
                  <a-button type="info" class="btn-upload">
                    <i class="bi bi-file-earmark-arrow-up-fill"></i> {{ $t('Button.Upload') }}
                  </a-button>
                </a-upload>
              </a-form-model-item>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="12" align="end">
            <a-button
                type="primary"
                :disabled="isBlockSubmit"
                @click="onSubmit"
                style="margin-right:10px"
            >
              {{ $t('Button.SendRequest') }}
            </a-button>
          </a-col>

          <a-col :span="12" align="start">
            <a-button
                onclick="history.back()"
            >
              {{ $t('Button.Back') }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from '../../../layout/AppMaster.vue'
import PageTitle from '@/components/PageTitle'
import { mapGetters } from 'vuex';
import helper from "@/until/helper";
import MerchantOnboard from "@/constants/MerchantOnboard";
import CountryCodeConstant from "../../../constants/CountryCode";
import DatePicker from '@/components/DatePicker'
import moment from "moment/moment";

export default {
  name: 'CreateSubMerchantOnboard',
  components: {
    AppMasterVue,
    PageTitle,
    DatePicker
  },
  computed: {
    ...mapGetters({
      profileMerchant: "authentications/getProfileMerchant",
    }),
  },
  data() {
    return {
      title: 'Title.CreateSubMerchantOnboard',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.SubMerchantOnboardList',
          routeName: 'ListSubMerchantOnboard',
          active: true,
        },
        {
          name: 'Title.CreateSubMerchantOnboard',
          routeName: 'CreateMerchantOnboard',
          active: true
        }
      ],
      loading: false,
      dataCreateMerchantOBForm: {
        merchant_partner: '',
        merchant_name: '',
        short_name: '',
        type: 3,
        business_registration_number: '',
        business_registration_date: null,
        business_address: '',
        country: '',
        business_representative: '',
        nationality: '',
        representative_identification: '',
        representative_identification_date: null,
        email: '',
        careers: '',
        register_method: [],
        domain: [],
        files: []
      },
      register_method: [],
      rows: [],
      isBlockSubmit: true,
      CountryCodeConstant,
      currentDate: moment().format('DD-MM-YYYY'),
      fileList: []
    }
  },
  beforeMount() {},
  mounted() {
    this.register_method = MerchantOnboard.REGISTER_METHOD;
    this.dataCreateMerchantOBForm.merchant_partner = String(this.profileMerchant.id ?? '')
  },
  methods: {
    formatDate: helper.formatDate,
    isset: helper.isset,
    empty: helper.empty,
    addRow() {
      this.dataCreateMerchantOBForm.domain.push("");
    },
    removeRow(index) {
      this.dataCreateMerchantOBForm.domain.splice(index, 1);
    },
    filterCountryCode(inputValue, option) {
      const value = option.componentOptions.propsData.value.toLowerCase();
      const filteredOption = this.CountryCodeConstant.COUNTRY_CODE.find(
          (item) => item.name.toLowerCase() === value
      );

      if (filteredOption) {
        return (
            filteredOption.code.toLowerCase().includes(inputValue.toLowerCase()) ||
            filteredOption.name.toLowerCase().includes(inputValue.toLowerCase())
        );
      }

      return false;
    },
    validateForm() {
      if (!this.dataCreateMerchantOBForm.merchant_name) {
        this.isBlockSubmit = true;
        return;
      }
      if (this.dataCreateMerchantOBForm.merchant_name.length > 128) {
        this.$notification['error']({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('MerchantOnboard.message.merchantNameMaxLength'),
          duration: 5,
          style: {
            "margin-top": "50px"
          },
        });
        this.isBlockSubmit = true;
        return;
      }

      let shortName = this.dataCreateMerchantOBForm.short_name;
      if (!shortName) {
        this.isBlockSubmit = true;
        return;
      }
      if (shortName.length > 50) {
        this.$notification['error']({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('MerchantOnboard.message.shortNameTooLong'),
          duration: 5,
          style: {
            "margin-top": "50px",
          },
        });
        this.isBlockSubmit = true;
        return;
      }
      const regex = /[A-Z!@#$%^&*()+=\\-\\ ]/;
      const notRegex = /^(?:[a-z0-9]+)[a-z0-9]{0,49}$/;
      if (regex.test(shortName) || !notRegex.test(shortName)) {
        this.$notification['error']({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('MerchantOnboard.message.shortNameInvalid'),
          duration: 5,
          style: {
            "margin-top": "50px",
          },
        });
        this.isBlockSubmit = true;
        return;
      }

      if (!this.dataCreateMerchantOBForm.business_registration_number) {
        this.isBlockSubmit = true;
        return;
      }
      if (this.dataCreateMerchantOBForm.business_registration_number.length > 100) {
        this.$notification['error']({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('MerchantOnboard.message.businessRegistrationNumberMaxLength'),
          duration: 5,
          style: {
            "margin-top": "50px"
          },
        });
        this.isBlockSubmit = true;
        return;
      }

      if (!this.dataCreateMerchantOBForm.business_registration_date) {
        this.isBlockSubmit = true;
        return;
      }

      if (!this.dataCreateMerchantOBForm.business_address) {
        this.isBlockSubmit = true;
        return;
      }
      if (this.dataCreateMerchantOBForm.business_address.length > 255) {
        this.$notification['error']({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('MerchantOnboard.message.businessAddressMaxLength'),
          duration: 5,
          style: {
            "margin-top": "50px"
          },
        });
        this.isBlockSubmit = true;
        return;
      }

      if (!this.dataCreateMerchantOBForm.country) {
        this.isBlockSubmit = true;
        return;
      }
      if (this.dataCreateMerchantOBForm.country.length > 255) {
        this.$notification['error']({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('MerchantOnboard.message.countryMaxLength'),
          duration: 5,
          style: {
            "margin-top": "50px"
          },
        });
        this.isBlockSubmit = true;
        return;
      }

      if (!this.dataCreateMerchantOBForm.business_representative) {
        this.isBlockSubmit = true;
        return;
      }
      if (this.dataCreateMerchantOBForm.business_representative.length > 255) {
        this.$notification['error']({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('MerchantOnboard.message.businessRepresentativeMaxLength'),
          duration: 5,
          style: {
            "margin-top": "50px"
          },
        });
        this.isBlockSubmit = true;
        return;
      }

      if (!this.dataCreateMerchantOBForm.nationality) {
        this.isBlockSubmit = true;
        return;
      }

      const regexPassportOfRepresentative = /^[a-zA-Z0-9]+$/;
      if (!this.dataCreateMerchantOBForm.representative_identification) {
        this.isBlockSubmit = true;
        return;
      }
      if (!regexPassportOfRepresentative.test(this.dataCreateMerchantOBForm.representative_identification)) {
        this.$notification['error']({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('MerchantOnboard.message.passportOfRepresentativeInvalid'),
          duration: 5,
          style: {
            "margin-top": "50px"
          },
        });
        this.isBlockSubmit = true;
        return;
      }

      if (!this.dataCreateMerchantOBForm.representative_identification_date) {
        this.isBlockSubmit = true;
        return;
      }

      if (!this.dataCreateMerchantOBForm.email) {
        this.isBlockSubmit = true;
        return;
      }
      const regexMail = /^([a-zA-Z0-9+_-]+)(\.[a-zA-Z0-9+_-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]+$/;
      if (!regexMail.test(this.dataCreateMerchantOBForm.email)){
        this.$notification['error']({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('MerchantOnboard.message.emailInvalid'),
          duration: 5,
          style: {
            "margin-top": "50px"
          },
        });
        this.isBlockSubmit = true;
        return;
      }

      if (!this.dataCreateMerchantOBForm.careers) {
        this.isBlockSubmit = true;
        return;
      }
      if (this.dataCreateMerchantOBForm.careers.length > 255) {
        this.$notification['error']({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('MerchantOnboard.message.careersMaxLength'),
          duration: 5,
          style: {
            "margin-top": "50px"
          },
        });
        this.isBlockSubmit = true;
        return;
      }

      if (!this.dataCreateMerchantOBForm.register_method.length) {
        this.isBlockSubmit = true;
        return;
      }

      if (this.dataCreateMerchantOBForm.domain.length > 0) {
        const domainRegex = /^(?!:\/\/)(?=.{1,255}$)((.{1,63}\.){1,127}(?![0-9]*$)[a-z0-9-]+\.?)$/i;
        const hasError = this.dataCreateMerchantOBForm.domain.some((item) => {
          if(!item){
            return true;
          }
          else if (!domainRegex.test(item)) {
            this.$notification['error']({
              message: this.$t('Notice.Title.Notice'),
              description: this.$t('MerchantOnboard.message.domainInvalid'),
              duration: 5,
              style: {
                "margin-top": "50px",
              },
            });
            return true;
          }
          return false;
        });

        if (hasError) {
          this.isBlockSubmit = true;
          return;
        }
      }

      this.isBlockSubmit = false;
    },
    onChangeBusinessRegistrationDate({ date }) {
      this.dataCreateMerchantOBForm.business_registration_date = date;
    },
    onChangeShowSignerIdentificationDate({ date }) {
      this.dataCreateMerchantOBForm.representative_identification_date = date;
    },
    async onSubmit(){
      let dataInput = new FormData();
      dataInput.append('merchant_partner', this.dataCreateMerchantOBForm.merchant_partner);
      dataInput.append('merchant_name', this.dataCreateMerchantOBForm.merchant_name);
      dataInput.append('short_name', this.dataCreateMerchantOBForm.short_name);
      dataInput.append('type', this.dataCreateMerchantOBForm.type);
      dataInput.append('business_registration_number', this.dataCreateMerchantOBForm.business_registration_number);
      dataInput.append('business_registration_date', this.dataCreateMerchantOBForm.business_registration_date);
      dataInput.append('business_address', this.dataCreateMerchantOBForm.business_address);
      dataInput.append('country', this.dataCreateMerchantOBForm.country);
      dataInput.append('business_representative', this.dataCreateMerchantOBForm.business_representative);
      dataInput.append('nationality', this.dataCreateMerchantOBForm.nationality);
      dataInput.append('representative_identification', this.dataCreateMerchantOBForm.representative_identification);
      dataInput.append('representative_identification_date', this.dataCreateMerchantOBForm.representative_identification_date);
      dataInput.append('email', this.dataCreateMerchantOBForm.email);
      dataInput.append('careers', this.dataCreateMerchantOBForm.careers);
      dataInput.append('register_method', this.dataCreateMerchantOBForm.register_method ?? null);
      dataInput.append('domain', this.dataCreateMerchantOBForm.domain ?? null);
      this.fileList.forEach((fileObj, index) => {
        dataInput.append(`files_upload_${index}`, fileObj);
      });
      this.isBlockSubmit = true;
      await this.$store.dispatch("merchantOnboard/createSubMerchantOnboard", dataInput).then(response => {
            if (response.data) {
              this.$notification['success']({
                message: this.$t('Notice.Title.Notice'),
                description: this.$t('MerchantOnboard.message.CreateMerchantSuccess'),
                duration: 5,
                style: {
                  "margin-top": "50px"
                },
              });
              this.isBlockSubmit = false;
              this.$router.push({ name: 'SubMerchantOnboardDetail', params: {id: response.data.onboard_id}})
            }
            else {
              if (response.code !== 0) {
                this.$notification['error']({
                  message: this.$t('Notice.Title.Notice'),
                  description: this.$t('MerchantOnboard.message.CreateMerchantFail'),
                  duration: 5,
                  style: {
                    "margin-top": "50px"
                  },
                });
                this.isBlockSubmit = false;
              }
            }
      })
    },
    beforeUploadFile(file) {
      const acceptedTypes = [
        'application/msword', // doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
        'application/pdf', // pdf
        'text/plain', // txt
        'application/rtf', // rtf
        'image/jpeg', // jpg, jpeg
        'image/png', // png
        'application/vnd.ms-excel', // xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
        'application/vnd.ms-powerpoint', // ppt
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
        'application/zip', // zip
        'application/x-rar-compressed', // rar
        'application/x-7z-compressed' // 7z
      ];

      const checkType = acceptedTypes.includes(file.type);
      if (!checkType) {
        this.$notification.error({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('MerchantOnboard.message.mimes'),
          duration: 5,
          style: { 'margin-top': '50px' },
        });
        return false;
      }

      const isLt15M = file.size / 1024 / 1024 <= 15;
      if (!isLt15M) {
        this.$notification.error({
          message: this.$t('Notice.Title.Notice'),
          description: this.$t('MerchantOnboard.message.sizes'),
          duration: 5,
          style: { 'margin-top': '50px' },
        });
        return false;
      }

      const isValid = checkType && isLt15M;
      if (!isValid) {
        this.fileList = this.fileList.filter(f => f.uid !== file.uid);
      }
      this.handleUpload(file);
      return isValid;
    },
    handleRemove(file) {
      console.log(file)
      this.fileList = this.fileList.filter(f => f.uid !== file.uid);
    },
    handleUpload(file) {
      this.fileList = [...this.fileList, file];
    },
    uploadfile() {
      return false;
    },
  },
  watch: {
    dataCreateMerchantOBForm: {
      handler: 'validateForm',
      deep: true,
    }
  }
}
</script>

<style scoped>
.domain-table>:not(:first-child) {
  border-top: none;
}
.merchant-onboard-card .ant-form-item .ant-form-item-label {
  margin-bottom: 8px !important;
}
.btn-upload{
  width: 90px;
  height: 45px;
  background-color: #E1F0FF;
  color: #3699FF;
}
.btn-upload:hover{
  background-color: #E1F0FF;
  border-color: #E1F0FF;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, .5), 0 3px 4px 3px rgba(60, 64, 67, .2) !important;
}
</style>