const merchantList = state => state.merchantList;
const merchantDetail = state => state.merchantDetail;
const getCurrentPage = state => state.currentPage;
const getTotalRow = state => state.total;
const getPerPage = state => state.perPage;

export default {
    merchantList,
    merchantDetail,
    getCurrentPage,
    getTotalRow,
    getPerPage,
}