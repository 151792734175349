const LEGAL_NOT_EVALUATED = 1;
const LEGAL_EVALUATING = 2;
const LEGAL_APPROVED_CONNECTION = 3;
const LEGAL_ADDITIONAL_REQUIREMENTS = 4;
const LEGAL_ADDED = 5;
const LEGAL_CONNECTION_REFUSED = 6;

const RISK_NOT_EVALUATED = 1;
const RISK_EVALUATING = 2;
const RISK_APPROVED_CONNECTION = 3;
const RISK_ADDITIONAL_REQUIREMENTS = 4;
const RISK_ADDED = 5;
const RISK_PENDING_APPROVAL = 6;
const RISK_CONNECTION_REFUSED = 7;

const NOT_EVALUATED = 0;
const EVALUATING = 1;
const APPROVED_CONNECTION = 2;
const CONNECTION_REFUSED = 3;
const ADDITIONAL_REQUIREMENTS = 4;
const ADDED = 5;

const OB_STATUS = {
    [NOT_EVALUATED]: 'MerchantOnboard.notEvaluated',
    [EVALUATING]: 'MerchantOnboard.evaluating',
    [APPROVED_CONNECTION]: 'MerchantOnboard.approvedConnection',
    [CONNECTION_REFUSED]: 'MerchantOnboard.connectionRefused',
    [ADDITIONAL_REQUIREMENTS]: 'MerchantOnboard.additionalRequirements',
};

const REGISTER_METHOD = {
    'ATM': 'Constant.TransactionMethod.AtmCard',
    'BANKTRANSFER': 'Constant.TransactionMethod.Collection',
    'NINEPAYWALLET': 'Constant.TransactionMethod.Wallet9Pay',
    'DISBURSEMENT': 'Constant.TransactionMethod.Installment'
};

export default {
    LEGAL_NOT_EVALUATED,
    LEGAL_EVALUATING,
    LEGAL_APPROVED_CONNECTION,
    LEGAL_ADDITIONAL_REQUIREMENTS,
    LEGAL_ADDED,
    LEGAL_CONNECTION_REFUSED,
    RISK_NOT_EVALUATED,
    RISK_EVALUATING,
    RISK_APPROVED_CONNECTION,
    RISK_ADDITIONAL_REQUIREMENTS,
    RISK_ADDED,
    RISK_PENDING_APPROVAL,
    RISK_CONNECTION_REFUSED,
    OB_STATUS,
    NOT_EVALUATED,
    EVALUATING,
    APPROVED_CONNECTION,
    CONNECTION_REFUSED,
    ADDITIONAL_REQUIREMENTS,
    ADDED,
    REGISTER_METHOD
}


// const LEGAL_STATUS = [
//     1 => 'Chưa thẩm định',
//     2 => 'Đang thẩm định',
//     3 => 'Duyệt kết nối',
//     4 => 'Yêu cầu bổ sung',
//     5 => 'Đã bổ sung',
//     6 => 'Từ chối kết nối',
// ];
//
// const RISK_STATUS = [
//     1 => 'Chưa thẩm định',
//     2 => 'Đang thẩm định',
//     3 => 'Duyệt kết nối',
//     4 => 'Yêu cầu bổ sung',
//     5 => 'Đã bổ sung',
//     6 => 'Chờ duyệt',
//     7 => 'Từ chối kết nối'
// ];