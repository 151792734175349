import AppConstant from "../constants/App";
import ChannelConstant from "../constants/Channel";
import InstallmentStatusConstant from "../constants/InstallmentStatus";
import InvoiceStatusConstant from "../constants/InvoiceStatus";
import TransactionMethodConstant from "../constants/TransactionMethod";
import TransactionStatusConstant from "../constants/TransactionStatus";
import TransactionTypeConstant from "../constants/TransactionType";
import RefundConstant from "../constants/RefundStatus";
import RefundTypeConstant from "../constants/RefundType";
import DashBoardConstant from "../constants/DashBoard";
import UserTypeConstant from "../constants/UserType";
import ProfileStatusConstant from "../constants/ProfileStatus";
import WalletOrderStatusConstant from "../constants/WalletOrderStatus";
import TransBankInfoConstant from "../constants/TransBankInfo";
import moment from "moment";
import RefundInstallmentRequestConstant from "@/constants/RefundInstallmentRequestStatus";
import IsRefundInstallmentRequestedConstant from "@/constants/IsInstallmentRequestChecked";
import InstallmentStatus from "../constants/InstallmentStatus";
import VirtualAccountsStatus from "@/constants/VirtualAccountsStatus";
import DisbursementByBatch from "../constants/DisbursementByBatch";
import DisbursementConstant from "@/constants/DisbursementTransaction";

function formatDate(createdAt) {
  var date = new Date(createdAt)
  var dformat = [
                date.getHours() < 10 ? '0' + date.getHours() : date.getHours(),
                date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(),
                date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
              ].join(':') + ' ' + 
              [
                date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
                date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,
                date.getFullYear()
              ].join('/');
  return dformat;
}

function showMoneyFormat(amount) {
  if (!isset(amount) || isNaN(amount)) {
    return '—';
  }
  var amountStr = amount.toString().split('.')
  amountStr[0] = amountStr[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return amountStr.join('.');
}

function isset(data) {
  try {
    return data !== undefined && data !== null;
  } catch (e) {
    return false;
  }
}

function empty(data) {
  try {
    return data === undefined || data === null || data === '' || data === [] || data === {} || data === 0;
  } catch (e) {
    return true;
  }
}

function getTransactionType(transactionType) {
  return TransactionTypeConstant.TRANSACTION_TYPE[transactionType];
}

function getTransactionTypeHtml(transactionType, showText) {
  switch(transactionType) {
    case TransactionTypeConstant.PURCHASE:
      return '<span class="text-info fw-bold">'
        + showText(TransactionTypeConstant.TRANSACTION_TYPE[transactionType])
        + '</span>';
    case TransactionTypeConstant.REFUND:
      return '<span class="text-warning fw-bold">'
      + showText(TransactionTypeConstant.TRANSACTION_TYPE[transactionType])
        + '</span>';
    case TransactionTypeConstant.INSTALLMENT:
      return '<span class="text-info fw-bold">'
      + showText(TransactionTypeConstant.TRANSACTION_TYPE[transactionType])
        + '</span>';
    case TransactionTypeConstant.LINK_CARD:
      return '<span class="text-success fw-bold">'
      + showText(TransactionTypeConstant.TRANSACTION_TYPE[transactionType])
        + '</span>';
    case TransactionTypeConstant.INSTALLMENT_REFUND:
      return '<span class="text-warning fw-bold">'
          + showText(TransactionTypeConstant.TRANSACTION_TYPE[transactionType])
          + '</span>';
    case TransactionTypeConstant.RECHARGE:
      return '<span class="text-info fw-bold">'
          + showText(TransactionTypeConstant.TRANSACTION_TYPE[transactionType])
          + '</span>';
  }
}

function getAllTransactionType() {
  return TransactionTypeConstant.TRANSACTION_TYPE;
}

function showStatus(status) {
  return TransactionStatusConstant.TRANSACTION_STATUS[status] ? TransactionStatusConstant.TRANSACTION_STATUS[status] : '—';
}

function showStatusInvoice(status) {
  return InvoiceStatusConstant.INVOICE_STATUS[status];
}

function showStatusInvoiceList() {
  return InvoiceStatusConstant.INVOICE_STATUS;
}

function showHandleBearer(feeBearer) {
  return AppConstant.BEARER_DISPLAY[feeBearer];
}

function showInstallmentStatus(status) {
  return InstallmentStatusConstant.INSTALLMENT_STATUS[status] ? InstallmentStatusConstant.INSTALLMENT_STATUS[status]: '—';
}

const showChannel = (channel) => {
  return ChannelConstant.CHANNEL_DISPLAY[channel];
}

function showAllChannel() {
  return ChannelConstant.CHANNEL_DISPLAY;
}

function getAllTransactionStatus() {
  return TransactionStatusConstant.LIST_TRANSACTION_STATUS;
}

function getAllTransactionDisbursementStatus() {
  return TransactionStatusConstant.TRANSACTION_STATUS_DISBURSEMENT;
}

function getDisbursementType() {
  return DisbursementConstant.TRANS_TYPE;
}

const showTransactionStatusHTML = (status, showText) => {
  switch(status) {
    case TransactionStatusConstant.PROCESSING:
      return '<span class="text-info fw-bold">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.PROCESSING])
        + '</span>';
    case TransactionStatusConstant.COMPLETED:
      return '<span class="text-success fw-bold">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.COMPLETED])
        + '</span>';
    case TransactionStatusConstant.CANCELLED:
      return '<span class="text-danger fw-bold">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.CANCELLED])
        + '</span>';
    case TransactionStatusConstant.RECEIVED_MONEY:
      return '<span class="text-primary fw-bold">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.RECEIVED_MONEY])
        + '</span>';
    case TransactionStatusConstant.UNDER_REVIEW:
      return '<span class="text-warning fw-bold">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.UNDER_REVIEW])
        + '</span>';
    case TransactionStatusConstant.FAILED:
      return '<span class="text-warning fw-bold">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.FAILED])
        + '</span>';
      case TransactionStatusConstant.REVERSED:
        return '<span class="text-warning fw-bold">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.REVERSED])
        + '</span>';
  }
}

const showTransactionStatusListHTML = (status, showText) => {
  switch(status) {
    case TransactionStatusConstant.PROCESSING:
      return '<p class="rounded bg-warning fw-bold text-center mb-0 p-1">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.PROCESSING])
        + '</p>';
    case TransactionStatusConstant.COMPLETED:
      return '<p class="rounded bg-success fw-bold text-white text-center mb-0 p-1">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.COMPLETED])
        + '</p>';
    case TransactionStatusConstant.CANCELLED:
      return '<p class="rounded bg-danger fw-bold text-white text-center mb-0 p-1">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.CANCELLED])
        + '</p>';
    case TransactionStatusConstant.RECEIVED_MONEY:
      return '<p class="rounded bg-success fw-bold text-white text-center mb-0 p-1">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.RECEIVED_MONEY])
        + '</p>';
    case TransactionStatusConstant.UNDER_REVIEW:
      return '<p class="rounded bg-warning fw-bold text-center mb-0 p-1">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.UNDER_REVIEW])
        + '</p>';
    case TransactionStatusConstant.LINKED:
      return '<p class="rounded bg-warning fw-bold text-center mb-0 p-1">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.LINKED])
        + '</p>';
    case TransactionStatusConstant.FAILED:
      return '<p class="rounded bg-danger fw-bold text-white text-center mb-0 p-1">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.FAILED])
        + '</p>';
    case TransactionStatusConstant.REVERSED:
      return '<p class="rounded bg-warning fw-bold text-center mb-0 p-1">'
        + showText(TransactionStatusConstant.TRANSACTION_STATUS[TransactionStatusConstant.REVERSED])
        + '</p>';
  }
}

const showInvoiceStatusDetailHTML = (status, showText) => {
  switch(status) {
    case InvoiceStatusConstant.PROCESSING:
      return '<p class="rounded bg-warning fw-bold text-center mb-0 p-1">'
        + showText(InvoiceStatusConstant.INVOICE_STATUS[InvoiceStatusConstant.PROCESSING])
        + '</p>';
    case InvoiceStatusConstant.SUCCESS:
      return '<p class="rounded bg-success fw-bold text-white text-center mb-0 p-1">'
        + showText(InvoiceStatusConstant.INVOICE_STATUS[InvoiceStatusConstant.SUCCESS])
        + '</p>';
  }
}

function showFormatDate(data = null, format = "DD/MM/YYYY HH:mm:ss") {
  if (moment(data).isValid) {
    return moment(data).format(format);
  }
  return moment().format(format);
}

function showAllTransactionMethod() {
  return TransactionMethodConstant.TRANSACTION_METHOD;
}

function showAllTransactionMethodForInvoice() {
  let methods = TransactionMethodConstant.TRANSACTION_METHOD;

  return methods;
}

function showAllTransactionMethodForInvoiceSubBNPL () {
  var methods = TransactionMethodConstant.TRANSACTION_METHOD;
  methods[TransactionMethodConstant.WALLET] = 'Constant.TransactionMethod.Wallet9Pay';
  methods[TransactionMethodConstant.VN_PAY] = 'Constant.TransactionMethod.WalletVNPay';
  methods[TransactionMethodConstant.ZALO_PAY] = 'Constant.TransactionMethod.WalletZaloPay';
  methods[TransactionMethodConstant.INSTALLMENT] = 'Constant.TransactionMethod.Installment';
  delete methods[TransactionMethodConstant.BUY_NOW_PAY_LATER];

  return methods;
}

const showRefundStatusHTML = (status, showText) => {
  switch(status) {
    case RefundConstant.WAITING:
      return '<span class="text-warning fw-bold">' + showText(RefundConstant.STATUS[RefundConstant.WAITING]) + '</span>';
    case RefundConstant.REJECTED:
      return '<span class="text-danger fw-bold">' + showText(RefundConstant.STATUS[RefundConstant.REJECTED]) + '</span>';
    case RefundConstant.CANCELLED:
      return '<span class="text-danger fw-bold">' + showText(RefundConstant.STATUS[RefundConstant.CANCELLED]) + '</span>';
    default:
      return '<span class="text-info fw-bold">' + showText(RefundConstant.STATUS[RefundConstant.APPROVED]) + '</span>';
  }
}

function showRefundType(refundType, showText) {
  if (refundType == RefundTypeConstant.FULL_REFUND)
    return showText(RefundTypeConstant.TYPE_OF_REFUND[RefundTypeConstant.FULL_REFUND]);
  return showText(RefundTypeConstant.TYPE_OF_REFUND[RefundTypeConstant.PARTIAL_REFUND]);
}

const showStatusInvoiceWalletHTML = (status, showText) => {
  switch(status) {
    case WalletOrderStatusConstant.SUCCESS:
      return '<p class="rounded bg-success fw-bold text-white text-center mb-0 p-1">'
        + showText(WalletOrderStatusConstant.ORDER_STATUS[WalletOrderStatusConstant.SUCCESS])
        + '</p>';
    case WalletOrderStatusConstant.FAIL:
      return '<p class="rounded bg-danger fw-bold text-white text-center mb-0 p-1">'
        + showText(WalletOrderStatusConstant.ORDER_STATUS[WalletOrderStatusConstant.FAIL])
        + '</p>';
    case WalletOrderStatusConstant.CANCEL:
      return '<p class="rounded bg-danger fw-bold text-white text-center mb-0 p-1">'
        + showText(WalletOrderStatusConstant.ORDER_STATUS[WalletOrderStatusConstant.CANCEL])
        + '</p>';
    case WalletOrderStatusConstant.CREATED:
      return '<p class="rounded fw-bold text-center mb-0 p-1">'
        + showText(WalletOrderStatusConstant.ORDER_STATUS[WalletOrderStatusConstant.CREATED])
        + '</p>';
    case WalletOrderStatusConstant.EXPIRED_SESSION:
      return '<p class="rounded bg-danger fw-bold text-white text-center mb-0 p-1">'
        + showText(WalletOrderStatusConstant.ORDER_STATUS[WalletOrderStatusConstant.EXPIRED_SESSION])
        + '</p>';
    case WalletOrderStatusConstant.PROCESSING:
      return '<p class="rounded bg-warning fw-bold text-center mb-0 p-1">'
        + showText(WalletOrderStatusConstant.ORDER_STATUS[WalletOrderStatusConstant.PROCESSING])
        + '</p>';
    case WalletOrderStatusConstant.PAYMENT_SUCCESS:
      return '<p class="rounded bg-success fw-bold text-white text-center mb-0 p-1">'
        + showText(WalletOrderStatusConstant.ORDER_STATUS[WalletOrderStatusConstant.PAYMENT_SUCCESS])
        + '</p>';
    case WalletOrderStatusConstant.PAYMENT_PENDING:
      return '<p class="rounded bg-warning fw-bold text-center mb-0 p-1">'
        + showText(WalletOrderStatusConstant.ORDER_STATUS[WalletOrderStatusConstant.PAYMENT_PENDING])
        + '</p>';
    default:
      return '<p class="rounded fw-bold text-center mb-0 p-1">'
        + status
        + '</p>';
  }
}


function convertDayToDateTime(number = 1, type = DashBoardConstant.DAYS) {
  switch (type) {
    case DashBoardConstant.DAYS:
      var startDay = moment().subtract(number - 1, "days").format(AppConstant.FORMAT_DATE_TIME);
      var endDay = moment().format(AppConstant.FORMAT_DATE_TIME);
      return {
        "from": startDay,
        "to": endDay
      };
    case DashBoardConstant.WEEK:
      var startWeek = moment().subtract((number - 1), "week").startOf('isoWeek').format(AppConstant.FORMAT_DATE_TIME);
      var endWeek = moment().endOf('isoWeek').format(AppConstant.FORMAT_DATE_TIME);
      return {
        "from": startWeek,
        "to": endWeek
      };
    case DashBoardConstant.MONTH:
      var startMonth = moment().subtract((number - 1), "month").startOf('month').format(AppConstant.FORMAT_DATE_TIME);
      var endMonth = moment().endOf('month').format(AppConstant.FORMAT_DATE_TIME);
      return {
        "from": startMonth,
        "to": endMonth
      };
    default:
      return {}
  }
}

function getStartDateForFilter(type = DashBoardConstant.TODAY) {
  switch (type) {
    case DashBoardConstant.TODAY:
      return moment().format(AppConstant.FORMAT_DATE_TIME);
    case DashBoardConstant.THIS_WEEK:
      return moment().startOf('isoWeek').format(AppConstant.FORMAT_DATE_TIME);
    case DashBoardConstant.THIS_MONTH:
      return moment().startOf('month').format(AppConstant.FORMAT_DATE_TIME);
    case DashBoardConstant.THIS_QUARTER:
      return moment().startOf('quarter').format(AppConstant.FORMAT_DATE_TIME);
    default:
      return '';
  }
}

function checkPermission(user, permissions) {
  if (user && (user.type == UserTypeConstant.MASTER_MERCHANT || user.type == UserTypeConstant.MERCHANT || user.type == UserTypeConstant.SUB_MASTER_MERCHANT)) {
    return true;
  }

  if (Array.isArray(permissions)) {
    return user.permissions.find(item => permissions.includes(item.name)) !== undefined;
  }

  return user.permissions.find(item => item.name == permissions) !== undefined
}

function checkShowDisbursementRequest(user) {
  if (user && (user.id === 54 || user.id === 1939)) {
    return true;
  }
}

function checkIsMasterMerchant(user) {
  if (user && (user.type == UserTypeConstant.MASTER_MERCHANT || user.type == UserTypeConstant.SUB_MASTER_MERCHANT)) {
    return true;
  }
  return false;
}

function checkIsLeadMerchant(user) {
  if (user && (user.type == UserTypeConstant.MASTER_MERCHANT || user.type == UserTypeConstant.MERCHANT)) {
    return true;
  }
  return false;
}

function checkMerchantActive(user) {
  if (user && isset(user.profile) && isset(user.profile.status) && user.profile.status == AppConstant.MERCHANT_ACTIVE) {
    return true;
  }
  return false;
}

function checkMerchantActiveSchoolPayment(user) {
  if (checkIsMasterMerchant(user)) {
    return true;
  }

  if (user && isset(user.profile) && isset(user.profile.is_payment_school) && user.profile.is_payment_school) {
    return true;
  }

  return false;
}

function checkMerchantActiveSDKWallet(user) {
  if (checkIsMasterMerchant(user)) {
    if (
      isset(user.profile)
      && isset(user.profile.sub_merchant_wallet)
      && Object.keys(user.profile.sub_merchant_wallet).length > 0
    ) {
      return true;
    }

    return false;
  }

  if (user
    && isset(user.profile)
    && isset(user.profile.merchant_profile_extra)
    && isset(user.profile.merchant_profile_extra.is_sdk_whitelabel)
    && user.profile.merchant_profile_extra.is_sdk_whitelabel) {
    return true;
  }

  return false;
}

function getProfileStatus(status) {
  return ProfileStatusConstant.STATUS[status];
}
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

function formatNumber(value) {
  if (isNaN(value)) {
    return 0;
  }
  return Intl.NumberFormat("en-US").format(value);
}

function getCurrentTotalRow(totalRow, perPage, currentPage) {
  if (totalRow < perPage) {
    return totalRow;
  }
  if ((totalRow - perPage * (currentPage - 1)) < perPage) {
    return totalRow - perPage * (currentPage - 1);
  }
  return perPage;
}

function showPaymentTime(paymentTimes) {
  return paymentTimes === 1 ? 'Invoice.One' : 'Invoice.Multiple';
}

function showIsactiveInvoice(isActive) {
  return isActive === 1 ? 'Constant.App.ActiveStatus.Active' : 'Constant.App.ActiveStatus.Inactive';
}

function showInvoicePaymentTimeList() {
  return InvoiceStatusConstant.INVOICE_PAYMENT_TIMES;
}

function showFeePayer(setting) {
  var settingJson = JSON.parse(setting);
  var feeInvoices = parseInt(settingJson.feeInvoices);

  return AppConstant.FEE_PAYER[feeInvoices];
}

function showFeePayerTransactions(fee_payer) {
  return AppConstant.FEE_PAYER_TRANSACTIONS[fee_payer];
}

function checkMerchantActiveDisbursement(user) {
  if (checkIsMasterMerchant(user)) {
    return true;
  }

  if (user && isset(user.profile) && isset(user.profile.is_disbursement) && user.profile.is_disbursement) {
    return true;
  }

  return false;
}

function checkMerchantActiveVA(user){
  if (checkIsMasterMerchant(user)) {
    return true;
  }
  if (user && isset(user.profile) && isset(user.profile.is_collection) && user.profile.is_collection) {
    return true;
  }

  return false;
}

export function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

// Show status STEP
function showStatusStep(status) {
  return TransBankInfoConstant.TRANSACTION_STEP_STATUS[status];
}

// Show status STEP Class
function showStatusStepClass(status) {
  switch(status) {
    case TransBankInfoConstant.STATUS_COMPLETED_INT:
      return 'step_completed';
    case TransBankInfoConstant.STATUS_REVERSAL_INT:
    case TransBankInfoConstant.STATUS_FAILED_INT:
      return 'step_failed';
    case TransBankInfoConstant.STATUS_PENDING_INT:
    case TransBankInfoConstant.STATUS_PROCESSING_INT:
      return 'step_pending';
    default:
      return 'step_unrealized';
  }
}

function showStatusStepInstallment(status) {
  return InstallmentStatus.INSTALLMENT_STATUS[status];
}

function showStatusStepInstallmentClass(status) {
  switch(status) {
    case InstallmentStatus.SUCCESS:
      return 'step_completed';
    case InstallmentStatus.FAILED:
    case InstallmentStatus.CANCELED:
      return 'step_failed';
    case InstallmentStatus.WAIT_SEND_PARTNER:
    case InstallmentStatus.SENT_PARTNER:
    case InstallmentStatus.SENT_CANCEL_INSTALMENT:
      return 'step_pending';
    default:
      return 'step_unrealized';
  }
}

function getTransactionStep() {
  return TransBankInfoConstant.TRANSACTION_STEP;
}

function handleFeeTrans(record) {
  if (record.transaction_type === TransactionTypeConstant.PURCHASE || record.transaction_type === TransactionTypeConstant.LINK_CARD) {
    return record.receive_payment_fee + record.receive_processing_fee;
  }

  if (record.transaction_type === TransactionTypeConstant.REFUND) {
    return record.receive_processing_fee - record.receive_payment_fee;
  }

  if (record.transaction_type === TransactionTypeConstant.INSTALLMENT) {
    let receivedFee = record.order_installment.received_fee ?? 0;
    return record.receive_processing_fee + record.receive_payment_fee + receivedFee;
  }

  if(record.transaction_type === TransactionTypeConstant.INSTALLMENT_REFUND){
    let refundReceivedFee = record.order_installment_request.refundable_installment_fee ?? 0;
    return record.receive_processing_fee - record.receive_payment_fee - refundReceivedFee;
  }

  return record.receive_payment_fee + record.receive_processing_fee;
}

function showProfileStatus(status){
  return ProfileStatusConstant.SHOWPROFILESTATUS[status];
}

const showRefundInstallmentReqStatusHTML = (status, showText) => {
  switch(status) {
    case RefundInstallmentRequestConstant.PENDING:
      return '<span class="text-warning fw-bold">' + showText(RefundInstallmentRequestConstant.STATUS[RefundInstallmentRequestConstant.PENDING]) + '</span>';
    case RefundInstallmentRequestConstant.SENT:
      return '<span class="text-info fw-bold">' + showText(RefundInstallmentRequestConstant.STATUS[RefundInstallmentRequestConstant.SENT]) + '</span>';
    case RefundInstallmentRequestConstant.CANCEL:
      return '<span class="text-danger fw-bold">' + showText(RefundInstallmentRequestConstant.STATUS[RefundInstallmentRequestConstant.CANCEL]) + '</span>';
    case RefundInstallmentRequestConstant.FAIL:
      return '<span class="text-danger fw-bold">' + showText(RefundInstallmentRequestConstant.STATUS[RefundInstallmentRequestConstant.FAIL]) + '</span>';
    default:
      return '<span class="text-success fw-bold">' + showText(RefundInstallmentRequestConstant.STATUS[RefundInstallmentRequestConstant.SUCCESS]) + '</span>';
  }
}

const showIsInstallmentRequestChecked = (isRequest, showText) => {
  if (isRequest === IsRefundInstallmentRequestedConstant.N0_FEE_REFUND_INSTALLMENT_REQUESTED)
    return showText(IsRefundInstallmentRequestedConstant.TYPE_OF_REQUEST[IsRefundInstallmentRequestedConstant.N0_FEE_REFUND_INSTALLMENT_REQUESTED]);
  return showText(IsRefundInstallmentRequestedConstant.TYPE_OF_REQUEST[IsRefundInstallmentRequestedConstant.FEE_REFUND_INSTALLMENT_REQUESTED]);
}

function getAllVAStatus() {
  return VirtualAccountsStatus.LIST_VA_STATUS;
}

const showVAStatusListHTML = (status, showText) => {
  switch(status) {
    case VirtualAccountsStatus.INACTIVE:
      return '<p class="rounded bg-danger fw-bold text-center mb-0 p-1">'
          + showText(VirtualAccountsStatus.VA_STATUS[VirtualAccountsStatus.INACTIVE])
          + '</p>';
    case VirtualAccountsStatus.ACTIVE:
      return '<p class="rounded bg-success fw-bold text-white text-center mb-0 p-1">'
          + showText(VirtualAccountsStatus.VA_STATUS[VirtualAccountsStatus.ACTIVE])
          + '</p>';
  }
}

function showVAStatus(isActive) {
  return isActive === 1 ? 'Constant.VAStatus.ACTIVE' : 'Constant.VAStatus.INACTIVE';
}

function removeVietnameseTones(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
  str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
  str = str.replace(/đ/g,"d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g," ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
  return str;
}

const showAccountStatusListHTML = (status, showText) => {
  switch(status) {
    case DisbursementByBatch.VERIFIED:
      return '<p class="rounded fw-bold bg-success text-white text-center mb-0 p-1">'
        + showText(DisbursementByBatch.ACCOUNT_STATUS[DisbursementByBatch.VERIFIED])
        + '</p>';
    case DisbursementByBatch.INVALID:
      return '<p class="rounded fw-bold bg-danger text-white text-center mb-0 p-1">'
        + showText(DisbursementByBatch.ACCOUNT_STATUS[DisbursementByBatch.INVALID])
        + '</p>';
    case DisbursementByBatch.INVALID_ACCOUNT:
      return '<p class="rounded fw-bold bg-danger text-white text-center mb-0 p-1">'
        + showText(DisbursementByBatch.ACCOUNT_STATUS[DisbursementByBatch.INVALID_ACCOUNT])
        + '</p>';
    case DisbursementByBatch.VERIFIED_ACCOUNT:
      return '<p class="rounded fw-bold bg-success text-white text-center mb-0 p-1">'
        + showText(DisbursementByBatch.ACCOUNT_STATUS[DisbursementByBatch.VERIFIED_ACCOUNT])
        + '</p>';
    case DisbursementByBatch.PROCESSING:
      return '<a-spin />';
  }
}

const showHistoryStatusHTML = (status, showText) => {
  switch(status) {
    case DisbursementByBatch.STATUS_UPLOAD_COMPLETED:
      return '<p class="text-success text-center mb-0 p-1 fw-bold">'
        + showText(DisbursementByBatch.HISTORY_STATUS[DisbursementByBatch.STATUS_UPLOAD_COMPLETED])
        + '</p>';
    case DisbursementByBatch.STATUS_UPLOAD_FAILED:
      return '<p class="text-danger text-center mb-0 p-1 fw-bold">'
        + showText(DisbursementByBatch.HISTORY_STATUS[DisbursementByBatch.STATUS_UPLOAD_FAILED])
        + '</p>';
    case DisbursementByBatch.STATUS_UPLOAD_INITIALIZE:
      return '<p class="text-gray text-center mb-0 p-1 fw-bold">'
        + showText(DisbursementByBatch.HISTORY_STATUS[DisbursementByBatch.STATUS_UPLOAD_INITIALIZE])
        + '</p>';
    case DisbursementByBatch.STATUS_UPLOAD_PROCESSING:
      return '<p class="text-warning text-center mb-0 p-1 fw-bold">'
        + showText(DisbursementByBatch.HISTORY_STATUS[DisbursementByBatch.STATUS_UPLOAD_PROCESSING])
        + '</p>';
  }
}

function isOffSms(isOffSms)
{
  if (isOffSms === 'OFF') {
    return true;
  }

  return false
}

export default {
  formatDate,
  showMoneyFormat,
  isset,
  empty,
  getTransactionType,
  getTransactionTypeHtml,
  getAllTransactionType,
  showStatus,
  showStatusInvoice,
  showStatusInvoiceList,
  showHandleBearer,
  showInstallmentStatus,
  showChannel,
  showAllChannel,
  getAllTransactionStatus,
  showTransactionStatusHTML,
  showTransactionStatusListHTML,
  showFormatDate,
  showAllTransactionMethod,
  showAllTransactionMethodForInvoice,
  showAllTransactionMethodForInvoiceSubBNPL,
  showRefundStatusHTML,
  showRefundType,
  showStatusInvoiceWalletHTML,
  convertDayToDateTime,
  getStartDateForFilter,
  checkPermission,
  checkIsMasterMerchant,
  checkIsLeadMerchant,
  checkMerchantActive,
  checkMerchantActiveSchoolPayment,
  checkMerchantActiveSDKWallet,
  uuidv4,
  getProfileStatus,
  decimalAdjust,
  formatNumber,
  getCurrentTotalRow,
  showPaymentTime,
  showIsactiveInvoice,
  showInvoicePaymentTimeList,
  getAllTransactionDisbursementStatus,
  showFeePayer,
  showFeePayerTransactions,
  showStatusStep,
  showStatusStepClass,
  getTransactionStep,
  checkMerchantActiveDisbursement,
  checkShowDisbursementRequest,
  handleFeeTrans,
  showProfileStatus,
  showRefundInstallmentReqStatusHTML,
  showIsInstallmentRequestChecked,
  showStatusStepInstallmentClass,
  showStatusStepInstallment,
  showInvoiceStatusDetailHTML,
  getAllVAStatus,
  showVAStatusListHTML,
  checkMerchantActiveVA,
  showVAStatus,
  removeVietnameseTones,
  getDisbursementType,
  showAccountStatusListHTML,
  showHistoryStatusHTML,
  isOffSms
}
