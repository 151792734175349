<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"></PageTitle>
    <section class="section">
      <a-spin :spinning="this.loading" size="large">
        <div class="card">
          <div v-if="merchantDetail">
            <div class="row">
              <div class="col-xl-6 left-content">
                <h5 class="card-title">{{ $t('Label.MerchantOnboardInfo') }}</h5>

                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.businessRegistrationName') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.merchant_name }}</div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.merchantShortName') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.short_name }}</div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.businessRegistrationNumber') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.business_registration_number }}</div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.businessLicenseIssueDate') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.business_registration_date }}</div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.businessAddress') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.business_address }}</div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.country') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.country }}</div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.businessRepresentative') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.business_representative }}</div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.nationality') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.nationality }}</div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.passportOfRepresentative') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.representative_identification }}</div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.representativeDocumentIssueDate') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.representative_identification_date }}</div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.email') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.email }}</div>
                </div>
              </div>
              <div class="col-xl-6 right-content">
                <h5 class="card-title">{{ $t('Label.MerchantAppraisalInformation') }}</h5>

                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.appraisalStatus') }}</div>
                  <div class="col-lg-8 col-md-7" v-html="showAccountStatusListHTML(merchantDetail.onboard_status)"></div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.riskReport') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.report.risk }}</div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5 label">{{ $t('MerchantOnboard.form.legalReport') }}</div>
                  <div class="col-lg-8 col-md-7">{{ merchantDetail.report.legal }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 left-content">
                <h5 class="card-title">{{ $t('Label.BusinessInfo') }}</h5>

                <div class="row">
                  <div class="col-lg-12 col-md-12 label" style="padding-bottom: 18px; font-weight: 600;">{{ $t('MerchantOnboard.form.careers') }}</div>
                  <div class="col-lg-12 col-md-12">
                    <a-input
                        style="pointer-events: none"
                        class="color-black"
                        v-model.trim="merchantDetail.careers"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 label" style="padding-bottom: 18px; font-weight: 600;">{{ $t('MerchantOnboard.form.register_method') }}</div>
                  <div class="col-lg-12 col-md-12">
                    <a-checkbox-group
                        :value="merchantDetail.register_method"
                        style="width: 100%;"
                    >
                      <a-row>
                        <a-col
                            v-for="(methodName, methodKey) in register_method"
                            :key="methodKey"
                            :span="12"
                            style="padding: 0 0 14px;"
                        >
                          <a-checkbox :value="methodKey" style="pointer-events: none;">
                            {{ $t(methodName) }}
                          </a-checkbox>
                        </a-col>
                      </a-row>
                    </a-checkbox-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 label" style="padding-bottom: 18px; font-weight: 600;">{{ $t('MerchantOnboard.form.domain') }}</div>
                  <div class="col-lg-12 col-md-12">
                    <div class="table-responsive mb-2">
                      <table class="table table-bordered table-hover">
                        <thead>
                        <tr>
                          <th class="text-center">{{ $t('MerchantOnboard.no') }}</th>
                          <th class="text-center">{{ $t('MerchantOnboard.form.domainName') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(record, index) in merchantDetail.domain" :key="index">
                          <td class="text-center">{{ index + 1 }}</td>
                          <td class="text-left">{{ record }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 col-md-12 label" style="padding-bottom: 18px; font-weight: 600;">{{ $t('MerchantOnboard.form.appraisalDocumentsAttached') }}</div>
                  <div class="col-lg-12 col-md-12">
                    <div class="table-responsive mb-2">
                      <table class="table table-bordered table-hover">
                        <tbody>
                        <tr v-for="(record, index) in merchantDetail.files" :key="index">
                          <td class="text-left"><a :href="record" target="_blank">{{ record }}</a></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row btn-content">
              <div class="text-center">
                <a-button v-if="!isBlockEdit" type="primary" style="margin-right:10px">
                  <router-link  :to="{name: 'UpdateSubMerchantOnboard'}">
                    {{ $t('Button.Edit') }}
                  </router-link>
                </a-button>
                <a-button>
                  <router-link :to="{name: 'ListSubMerchantOnboard'}">
                    {{ $t('Button.Back') }}
                  </router-link>
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </section>
  </AppMasterVue>
</template>

<script>
import AppMasterVue from "@/layout/AppMaster.vue";
import PageTitle from "@/components/PageTitle";
import {mapGetters} from "vuex";
import helper from "@/until/helper";
import MerchantOnboard from "@/constants/MerchantOnboard";

export default {
  name: "SubMerchantOnboardDetail",
  components: {
    AppMasterVue,
    PageTitle,
  },
  data(){
    return {
      loading: false,
      title: 'Title.SubMerchantOnboardDetail',
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.SubMerchantOnboardList',
          routeName: 'ListSubMerchantOnboard',
          active: true,
        },
        {
          name: 'Title.SubMerchantOnboardDetail',
          routeName: 'SubMerchantOnboardDetail',
          active: true
        }
      ],
      register_method: [],
      isBlockEdit: true
    }
  },
  computed:{
    ...mapGetters({
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
      lang: 'authentications/getLang',
      profileMerchant: "authentications/getProfileMerchant",
      merchantDetail: "merchantOnboard/merchantDetail",
    })
  },
  mounted(){
    this.callApiMerchantDetail();
    this.register_method = MerchantOnboard.REGISTER_METHOD;
  },
  methods:{
    isset: helper.isset,
    handleLoading: function(){
      this.loading = !this.loading;
    },
    async callApiMerchantDetail() {
      this.handleLoading();
      let data = {
        id: this.$route.params.id
      }
      await this.$store.dispatch("merchantOnboard/getInfoSubMerchantOnboard", data);
      const onboardStatus = [MerchantOnboard.NOT_EVALUATED, MerchantOnboard.ADDITIONAL_REQUIREMENTS];
      if (onboardStatus.includes(this.merchantDetail.onboard_status)){
        this.isBlockEdit = false;
      }
      this.handleLoading();
    },
    showAccountStatusListHTML(appraisalStatus) {
      if (appraisalStatus === MerchantOnboard.NOT_EVALUATED){
        return '<p class="fw-bold text-dark text-left mb-0">'
            + this.$t(MerchantOnboard.OB_STATUS[MerchantOnboard.NOT_EVALUATED])
            + '</p>';
      }

      if (appraisalStatus === MerchantOnboard.EVALUATING){
        return '<p class="fw-bold text-warning text-left mb-0">'
            + this.$t(MerchantOnboard.OB_STATUS[MerchantOnboard.EVALUATING])
            + '</p>';
      }

      if (appraisalStatus === MerchantOnboard.APPROVED_CONNECTION){
        return '<p class="fw-bold text-success text-left mb-0">'
            + this.$t(MerchantOnboard.OB_STATUS[MerchantOnboard.APPROVED_CONNECTION])
            + '</p>';
      }

      if (appraisalStatus === MerchantOnboard.CONNECTION_REFUSED){
        return '<p class="fw-bold text-danger text-left mb-0">'
            + this.$t(MerchantOnboard.OB_STATUS[MerchantOnboard.CONNECTION_REFUSED])
            + '</p>';
      }

      if (appraisalStatus === MerchantOnboard.ADDITIONAL_REQUIREMENTS){
        return '<p class="fw-bold text-primary text-left mb-0">'
            + this.$t(MerchantOnboard.OB_STATUS[MerchantOnboard.ADDITIONAL_REQUIREMENTS])
            + '</p>';
      }

      return '--';
    },
  },
  watch:{}
}
</script>

<style>
  .left-content, .right-content {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .btn-content {
    padding: 20px 0;
  }
  .btn-content .btn {
    margin: 0 7px;
    color: #fff;
  }

  .left-content .row, .right-content .row {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    padding-bottom: 18px;
  }
  .table>:not(:first-child) {
    border-top: 1px solid !important;
  }
</style>