<template>
  <a-date-picker
      @change="onChange"
      :value="getDate()"
      format="DD-MM-YYYY"
      class="w-100"
      :locale="locale"
      :allowClear="false"
      :disabled="disabled"
      @openChange="onOpenChange"
      :disabled-date="disabledAfterToday"
  />
</template>

<script>
import moment from "moment";
import store from '../store';

var user = store.getters["authentications/getInfoUserLogin"];
moment.locale((user && user.lang) ? user.lang : 'vi');

export default {
  name: "DatePicker",
  props: {
    date: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const userLang = (user && user.lang) ? user.lang : 'vi';
    moment.locale(userLang);

    return {
      initDate: this.date,
      locale: {
        lang: {
          locale: userLang,
          yearFormat: "YYYY",
          dateFormat: "DD-MM-YYYY",
          dayFormat: "D",
          dateTimeFormat: "DD-MM-YYYY HH:mm:ss",
        },
      },
    };
  },
  watch: {
    date(newVal) {
      this.initDate = newVal;
    },
  },
  methods: {
    onChange(value, dateString) {
      this.initDate = dateString;
      this.$emit("update:date", dateString);
      this.$emit("onChangeDate", { date: dateString });
    },
    getDate() {
      return this.initDate ? moment(this.initDate, "DD-MM-YYYY") : null;
    },
    onOpenChange() {
      const userLang = (user && user.lang) ? user.lang : 'vi';
      moment.locale(userLang);

      this.locale = {
        lang: {
          locale: userLang,
          yearFormat: "YYYY",
          dateFormat: "DD-MM-YYYY",
          dayFormat: "D",
          dateTimeFormat: "DD-MM-YYYY HH:mm:ss",
        },
      };
    },
    disabledAfterToday(date) {
      const today = new Date();
      return date > today
    },
  },
};
</script>
