<template>
  <AppMasterVue>
    <PageTitle :title="title" :breadcrumbs="breadcrumbs"></PageTitle>
    <section class="section">
      <a-card class="mb-30 shadow">
        <div class="row">
          <div class="col-12 col-md-4 mt-2">
            <a-select
                ref="type"
                v-model="type"
                style="width: 100%"
                @change="onchangeType"
            >
              <a-icon slot="suffixIcon" type="file-protect" />
              <a-select-option value="">{{ $t('MerchantDisbursementLog.AllType') }}</a-select-option>
              <a-select-option
                  v-for="(type, id) in MerchantFreezeBalanceTypeConstant.TYPE_DISPLAY_FREEZE_TYPE"
                  :key="id"
                  :value="id"
              >
                {{ $t(type) }}
              </a-select-option>
            </a-select>
          </div>

          <div class="col-12 col-md-4 mt-2">
            <DateRangePicker
                :dateFrom.sync="dateFrom"
                :date-to.sync="dateTo"
                @onChangeDate="onChangeDate"
            ></DateRangePicker>
          </div>

          <div class="col-12 col-md-4 mt-2">
            <a-button type="primary" class="me-2" @click="search">
              {{ $t('Button.Search') }}
            </a-button>
            <a-button type="secondary" @click="clear">{{ $t('Button.Cancel') }}</a-button>
          </div>
        </div>
      </a-card>

      <a-card class="mb-30 shadow">
        <div class="row">
          <div class="col-12 col-md-6">
            <p>{{ $t('MerchantFreezeBalance.totalFreezeBalance') + ' : ' + showMoneyFormat(isset(totalFreezeBalance.totalFreezeBalance) ? totalFreezeBalance.totalFreezeBalance : 0) }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p>{{ $t('MerchantFreezeBalance.totalDecrementFreezeBalance') + ' : ' + showMoneyFormat(isset(totalFreezeBalance.totalDecrementFreezeBalance) ? totalFreezeBalance.totalDecrementFreezeBalance : 0) }}</p>
          </div>
        </div>
      </a-card>

      <a-spin :spinning="this.loading" size="large">
        <a-card class="mb-30 shadow">
          <a-table
              :data-source="merchantFreezeBalanceList"
              :columns="columns"
              :row-key="record => record.id"
              :pagination="false"
              class="mb-30 nowrap"
          >
            <template #customTitleNo>
              {{ $t('MerchantDisbursementLog.No') }}
            </template>
            <template slot="no" slot-scope="text, record, index">
              <p style="font-weight: bold">{{ (currentPage - 1) * perPage + index + 1 }}</p>
            </template>

            <template #customTitleMerchantName  v-if="checkIsMasterMerchant(user)">
              {{ $t('MerchantDisbursementLog.MerchantName') }}
            </template>
            <template slot="merchant_id" slot-scope="text, record"  v-if="checkIsMasterMerchant(user)">
              {{ checkIsMasterMerchant(user) ? merchants[record.merchant_id].name : '' }}
            </template>

            <template #customTitleCreatedAt>
              {{ $t('MerchantDisbursementLog.CreateDate') }}
            </template>
            <template slot="created_at" slot-scope="text, record">
              {{ formatDate(record.created_at) }}
            </template>

            <template #customTitleType>
              {{ $t('MerchantDisbursementLog.Type') }}
            </template>
            <template slot="type" slot-scope="text, record">
              {{ displayType(record.type, record.type_deposit) }}
            </template>

            <template #customTitleBeforeBalance>
              {{ $t('MerchantDisbursementLog.BeforeBalance') }}
            </template>
            <template slot="before_balance" slot-scope="text, record">
              <p class="mb-0">{{ showMoneyFormat(record.before_freeze_balance) }}</p>
            </template>

            <template #customTitleAmount>
              {{ $t('MerchantDisbursementLog.Amount') }}
            </template>
            <template slot="amount" slot-scope="text, record">
              {{ ( record.type == MerchantFreezeBalanceTypeConstant.DECREMENT_FREEZE) ? '-' : '+' }} {{ (record.type == MerchantFreezeBalanceTypeConstant.DECREMENT_FREEZE && record.decrement_freeze_amount !== '0.00') ? showMoneyFormat(record.decrement_freeze_amount) : showMoneyFormat(record.freeze_amount) }}
            </template>

            <template #customTitleAfterBalance>
              {{ $t('MerchantDisbursementLog.AfterBalance') }}
            </template>
            <template slot="after_balance" slot-scope="text, record">
              <p class="mb-0">{{ showMoneyFormat(record.after_freeze_balance) }}</p>
            </template>
          </a-table>

          <a-row>
            <a-col :span="8">
              {{ $t('ShowPaginateFooterRecords', {'total_row': getCurrentTotalRow(totalRow, perPage, currentPage), 'total': totalRow}) }}
            </a-col>
            <a-col :span="16" align="end">
              <a-pagination
                  v-model="currentPage"
                  :current="currentPage"
                  :page-size="perPage"
                  :total="totalRow"
              />
            </a-col>
          </a-row>
        </a-card>
      </a-spin>
    </section>
  </AppMasterVue>
</template>


<script>
import AppMasterVue from '../../../layout/AppMaster.vue'
import {mapGetters} from 'vuex';
import PageTitle from "@/components/PageTitle";
import DateRangePicker from "@/components/DateRangePicker";
import helper from "@/until/helper";
import MerchantFreezeBalanceType from "@/constants/MerchantFreezeBalanceType";
import moment from "moment/moment";

export default {
  name: "MerchantBalanceUsd",
  components: {
    AppMasterVue,
    PageTitle,
    DateRangePicker
  },
  data() {
    return {
      title: 'Sidebar.MerchantFreezeBalance',
      loading: false,
      breadcrumbs: [
        {
          name: 'Title.Home',
          routeName: 'Home',
          active: true,
        },
        {
          name: 'Sidebar.TransactionDisbursementManagement',
          routeName: null,
          active: false
        },
        {
          name: 'Sidebar.MerchantFreezeBalance',
          routeName: 'MerchantFreezeBalance',
          active: true,
        },
      ],
      MerchantFreezeBalanceTypeConstant: MerchantFreezeBalanceType,
      columns: [
        {
          key: "no",
          slots: { title: "customTitleNo" },
          scopedSlots: { customRender: "no" },
          width: 60,
        },
        {
          key: "merchant_id",
          slots: { title: "customTitleMerchantName" },
          scopedSlots: { customRender: "merchant_id" },
        },
        {
          key: "created_at",
          slots: { title: "customTitleCreatedAt" },
          scopedSlots: { customRender: "created_at" },
        },
        {
          key: "type",
          slots: { title: "customTitleType" },
          scopedSlots: { customRender: "type" },
        },
        {
          key: "before_balance",
          slots: { title: "customTitleBeforeBalance" },
          scopedSlots: { customRender: "before_balance" },
        },
        {
          key: "amount",
          slots: { title: "customTitleAmount" },
          scopedSlots: { customRender: "amount" },
        },
        {
          key: "after_balance",
          slots: { title: "customTitleAfterBalance" },
          scopedSlots: { customRender: "after_balance" },
        },
      ],
      currentPage: this.$route.query.page != undefined ? parseInt(this.$route.query.page) : 1,
      type: this.isset(this.$route.query.type) ? this.$route.query.type : '',
      typeDeposit: this.isset(this.$route.query.typeDeposit) ? this.$route.query.typeDeposit : '',
      dateFrom: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : this.isset(this.$route.query.date_from) ? this.$route.query.date_from : moment().startOf("month").format("DD/MM/YYYY"),
      dateTo: this.isset(this.$route.query.date_to) ? this.$route.query.date_to : moment().format("DD/MM/YYYY"),
      searchInput: [],
      searchType: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentications/getUserLogin',
      merchants: 'authentications/getMerchants',
      merchantFreezeBalanceList: 'merchantFreezeBalance/getMerchantFreezeBalanceList',
      totalRow: 'merchantFreezeBalance/getTotalRow',
      perPage: 'merchantFreezeBalance/getPerPage',
      totalFreezeBalance: 'merchantFreezeBalance/getTotalFreezeBalance',
    }),
  },
  mounted() {
    if (isNaN(this.merchants)) {
      this.merchantFilter = [];
      Object.keys(this.merchants).forEach(merchantId => {
        this.merchantFilter.push(parseInt(merchantId));
      });
    } else {
      this.merchantFilter = parseInt(this.merchants);
    }
    // set data
    this.searchInput = {
      page: this.currentPage,
      date_from: this.dateFrom,
      date_to: this.dateTo,
      merchant_id: this.merchantFilter,
    };
    this.callApiMerchantFreezeBalanceList();
  },
  methods: {
    formatDate: helper.formatDate,
    showMoneyFormat: helper.showMoneyFormat,
    isset: helper.isset,
    checkPermission: helper.checkPermission,
    checkIsMasterMerchant: helper.checkIsMasterMerchant,
    getCurrentTotalRow: helper.getCurrentTotalRow,
    handleLoading: function(){
      this.loading = !this.loading;
    },
    onChangeDate(value) {
      this.dateFrom = value.dateFrom;
      this.dateTo = value.dateTo;
    },
    onchangeType(value){
      switch (parseInt(value)) {
        case this.MerchantFreezeBalanceTypeConstant.DEPOSIT_AMOUNT:
          this.searchType = this.MerchantFreezeBalanceTypeConstant.INCREMENT_FREEZE;
          this.typeDeposit = [this.MerchantFreezeBalanceTypeConstant.DEPOSIT_INSTANT, this.MerchantFreezeBalanceTypeConstant.DEPOSIT_DEDUCT, this.MerchantFreezeBalanceTypeConstant.DEPOSIT_RETURN];
          break;
        case this.MerchantFreezeBalanceTypeConstant.DECREMENT_DEPOSIT_AMOUNT:
          this.searchType = this.MerchantFreezeBalanceTypeConstant.DECREMENT_FREEZE;
          this.typeDeposit = [this.MerchantFreezeBalanceTypeConstant.DEPOSIT_RETURN, this.MerchantFreezeBalanceTypeConstant.DEPOSIT_DECREMENT_FREEZE];
          break;
        case this.MerchantFreezeBalanceTypeConstant.HOLD_MONEY_AMOUNT:
          this.searchType = this.MerchantFreezeBalanceTypeConstant.INCREMENT_FREEZE;
          this.typeDeposit = [this.MerchantFreezeBalanceTypeConstant.DEPOSIT_INSTANT_HOLD, this.MerchantFreezeBalanceTypeConstant.DEPOSIT_DEDUCT_HOLD];
          break;
        case this.MerchantFreezeBalanceTypeConstant.DECREMENT_HOLD_MONEY_AMOUNT:
          this.searchType = this.MerchantFreezeBalanceTypeConstant.DECREMENT_FREEZE;
          this.typeDeposit = this.MerchantFreezeBalanceTypeConstant.DEPOSIT_RETURN_HOLD;
          break;
        default:
          this.searchType = '';
          this.typeDeposit = '';
          break;
      }
    },
    search() {
      this.searchInput = {
        page: this.currentPage,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        type: this.searchType,
        type_deposit: this.typeDeposit,
        merchant_id: this.merchantFilter,
      };
      this.callApiMerchantFreezeBalanceList();
    },
    clear() {
      this.merchantId = "";
      this.searchType = '';
      this.typeDeposit = '';
      this.type = "";
      this.dateFrom = moment().startOf("month").format("DD/MM/YYYY");
      this.dateTo = moment().format("DD/MM/YYYY");
      this.currentPage = 1;
      this.merchant_id = [];

      if (isNaN(this.merchants)) {
        this.merchant_id = [];
        Object.keys(this.merchants).forEach(merchantId => {
          this.merchant_id.push(parseInt(merchantId));
        });
      } else {
        this.merchant_id = parseInt(this.merchants);
      }

      this.search();
    },
    async callApiMerchantFreezeBalanceList() {
      this.handleLoading();
      await this.$store.dispatch("merchantFreezeBalance/index", this.searchInput);
      this.handleLoading();
    },
    displayType(type, typeDeposit) {
      if (type === this.MerchantFreezeBalanceTypeConstant.INCREMENT_FREEZE) {
        if ([this.MerchantFreezeBalanceTypeConstant.DEPOSIT_INSTANT, this.MerchantFreezeBalanceTypeConstant.DEPOSIT_DEDUCT, this.MerchantFreezeBalanceTypeConstant.DEPOSIT_RETURN].includes(typeDeposit)) {
          return this.$t('Constant.MerchantFreezeBalance.DepositAmount');
        } else if ([this.MerchantFreezeBalanceTypeConstant.DEPOSIT_INSTANT_HOLD, this.MerchantFreezeBalanceTypeConstant.DEPOSIT_DEDUCT_HOLD].includes(typeDeposit)) {
          return this.$t('Constant.MerchantFreezeBalance.HoldMoneyAmount');
        }
      }
      else if (type === this.MerchantFreezeBalanceTypeConstant.DECREMENT_FREEZE) {
        if ([this.MerchantFreezeBalanceTypeConstant.DEPOSIT_RETURN, this.MerchantFreezeBalanceTypeConstant.DEPOSIT_DECREMENT_FREEZE].includes(typeDeposit)) {
          return this.$t('Constant.MerchantFreezeBalance.DecrementDepositAmount');
        } else if (typeDeposit === this.MerchantFreezeBalanceTypeConstant.DEPOSIT_RETURN_HOLD) {
          return this.$t('Constant.MerchantFreezeBalance.DecrementHoldMoneyAmount');
        }
      }
    },
    setRouter() {
      this.$router.replace({
        name: "MerchantFreezeBalance",
        query: this.searchInput,
      }).catch(() => {});
    },
},
  watch: {
    currentPage: {
      handler: function (value) {
        this.searchInput = {
          page: value,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          type: this.searchType,
          type_deposit: this.typeDeposit,
          merchant_id: this.merchantFilter,
        };
        this.setRouter();
        this.callApiMerchantFreezeBalanceList();
      },
    },
  }
}
</script>